import { Col, Row } from "react-bootstrap"
import React from "react"
import "./event-details.scss"
import { useLocation } from "react-router-dom"
import Button from "../../../../../components/form/button/button"

const TaskInfo = ({ handleMediaAdd,setShowTaskMedia, readOnly }) => {
  const location = useLocation()
  const currentNavState = location.state
  const selectedRiskAssessment = currentNavState.selectedRiskAssessment
  const selectedTask = currentNavState.selectedTask
  const selectedEventName = currentNavState.selectedEventName



  return (
    <Row>
      <Col xs={7}>
        <div className="task-details">
          <div>Task: {selectedTask.name}</div>
          <div>Evaluate Event: {selectedEventName}</div>
          <div>
            Product-line: {selectedRiskAssessment?.product_detail?.name}{" "}
            Equipment model:{" "}
            {selectedRiskAssessment?.machine_detail
              ?.map(el => el.name)
              .join(", ")}
          </div>
        </div>
      </Col>
      <Col xs={5}>
        <div className="event-right-actions">
          {/* <a>View Internal Notes(3)</a> */}
          <a onClick={() => setShowTaskMedia(true)}>View Media</a>
          {!readOnly ? <div className="upload-media">
            <Button variant={"secondary-button"}>Upload Media</Button>
            <input multiple type="file" onChange={handleMediaAdd} />
          </div> : <></>}
        </div>
      </Col>
    </Row>
  )
}

export default TaskInfo
