import { Formik, Form, ErrorMessage } from 'formik';
import TextField from '../../components/form/textfield/textfield';
import Button from '../../components/form/button/button';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { sendFeedback } from '../../redux/feedback/feedbackSlice';
import { useRef } from 'react';
import { getLoginStateSelector } from '../../redux/selectors/loginSelector';
import './feedback.scss';


export default function UserFeedback() {

  const dispatch = useDispatch();
  const loginState = useSelector(getLoginStateSelector())
  const { isLoggedIn, loggedInUser } = loginState
  const messageInputStyle = {
    height: 88,
    marginTop: 20,
  }

    const initialValues = {
        email: loggedInUser.email,
        subject: '',
        message:''
      }


      const validate = Yup.object({
        subject: Yup.string().required('Subject is required'),
        message: Yup.string().required('Message is required')
      })

    const handleFeedbackFormSubmit = (values, {resetForm}) => {
      const payload = {
        subject: values.subject,
        text: values.message
      }
      dispatch(sendFeedback(payload))
      resetForm()
    }

    return(
        <div>
        <Formik
          initialValues = {initialValues}
          onSubmit = {handleFeedbackFormSubmit}
          validationSchema = {validate}
        >
          {(formik, resetForm) => (
            <Form>
              <div className='feedback-form mt-5'>
                <TextField
                  type="text"
                  label="Email ID"
                  name="email"
                  placeholder="Enter email"
                  disabled
                />
                <TextField
                  type="text"
                  label="Subject"
                  name="subject"
                  placeholder="Enter Subject"
                />
                <TextField
                  type="text"
                  label="Message"
                  name="message"
                  placeholder="Enter Message"
                  styles={messageInputStyle}
                />
                <div className='action-button mt-10'>
                  <Button
                    type='submit'
                    variant='submit-button'
                  >OK</Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
}