import {post, get, put, del, patch} from './http-methods';

const loginApi = {
  login: payload => post('/api/v1/login/', payload),
  signup: payload => post('/api/v1/signup/', payload),
};

const passwordReset = {
  passwordResetEmail: payload => post('/api/v1/forgot_password/', payload),
  verifyCode: payload => post('/api/v1/verify/', payload),
  passwordChange: payload => post('/api/v1/reset_password/', payload),
};

const userList = {
  getUsers: config => get('/api/v1/userlist/', config),
  editUser: (id, payload) => patch(`/api/v1/userlist/user/${id}/`,payload),
  deleteUser: id => del(`/api/v1/userlist/${id}/`),
  approveUser: (id, payload) => patch(`/api/v1/userlist/verify-user/${id}/`,payload)
};

const riskAssessments = {
  getProductLines: config => get('/api/v1/product-line/', config),
  createProductLine: payload => post('/api/v1/product-line/', payload),
  editProductLine: (id, payload) =>
    patch(`/api/v1/product-line/${id}/`, payload),
  deleteProductLine: id => del(`/api/v1/product-line/${id}/`),
  getProductLineModels: ({productLineId, config}) =>
    get(`/api/v1/product-line/product-model/${productLineId}/`, config),
  createProductModel: payload => post('/api/v1/machine/', payload),
  editProductModel: (id, payload) => patch(`/api/v1/machine/${id}/`, payload),
  deleteProductModel: id => del(`/api/v1/machine/${id}/`),

  // create Risk Assessment
  createRiskAssessment: payload => post('/api/v1/risk-assessment/', payload),
  // get Risk Assessments
  getRiskAssessments: (obj, config) => {
    if (obj.productLineId) {
      return get(
        `/api/v1/risk-assessment/product/${obj.productLineId}/`,
        config,
      );
    } else if (obj.modelId) {
      return get(`/api/v1/risk-assessment/model/${obj.modelId}/`, config);
    }
  },
  getRiskAssessmentById: id => get(`/api/v1/risk-assessment/${id}/`),
  deleteRiskAssessment: id => del(`/api/v1/risk-assessment/${id}/`),
  editRiskAssessment: (id, payload) =>
    patch(`/api/v1/risk-assessment/${id}/`, payload),
  getSubCategoriesForTask: () => get('/api/v1/sub-categories/'),
  deletedRiskAssessment: config =>
    get(`/api/v1/risk-assessment/deleted/`, config),
  restoreRiskAssessment: id => patch(`/api/v1/risk-assessment/restore/${id}/`),
  hardDeleteRiskAssessment: id => del(`/api/v1/risk-assessment/delete/${id}/`),
  getRiskAssessmentInfo: id => get(`/api/v1/risk-assessment/info/${id}/`),
};

const riskAssessmentsCore = {
  // get risk assessments tasks
  getRiskAssessmentsTasks: ({riskAssessmentId, config}) =>
    get(`/api/v1/risk-task/risk_assessment/${riskAssessmentId}/`, config),
  // get task detail
  getTaskDetail: taskId => get(`/api/v1/risk-task/${taskId}/`),
  // create risk assessment task
  createTask: payload => post('/api/v1/risk-task/', payload),
  updateTask: (id, payload) => patch(`/api/v1/risk-task/${id}/`, payload),
  deleteTask: taskId => del(`/api/v1/risk-task/${taskId}/`),
  getSubCategoriesForEvents: config =>
    get('/api/v1/event-sub-categories/', config),
  createSubCategoriesForEvents: payload =>
    post('/api/v1/event-sub-categories/', payload),
  // marking state for exposure to events
  markNoEvents: (id, payload) => patch(`/api/v1/event-task/${id}/`, payload),
  addEventSubCategory: payload =>
    post(`/api/v1/event-task/add-category/`, payload),
  deleteEventSubCategory: (id, payload) =>
    post(`/api/v1/event-task/remove-category/${id}/`, payload),
  // -----Risk Assessment nested data------
  // Limits of machine
  getMachineLimits: id => get(`/api/v1/limit-machine/risk_assessment/${id}/`),
  addMachineLimits: (id, payload) =>
    id
      ? patch(`/api/v1/limit-machine/${id}/`, payload)
      : post(`/api/v1/limit-machine/`, payload),

  //Event details
  saveEventDetail: payload => post('/api/v1/event-assessment/', payload),
  getEventDetail: ({eventId, config}) =>
    get(`/api/v1/event-assessment/event/${eventId}/`, config),
  updateEventDetail: (id, payload) =>
    patch(`/api/v1/event-assessment/${id}/`, payload),
  updateTaskMedia: (id, payload) =>
    post(`/api/v1/upload-media/task/${id}/`, payload),
  uploadMedia: payload => post(`/api/v1/upload-media/`, payload),
  addAssumption: (id, payload) =>
    id
      ? patch(`/api/v1/assumption/${id}/`, payload)
      : post(`/api/v1/assumption/`, payload),
  getAssumption: id => get(`/api/v1/assumption/risk_assessment/${id}/`),

  addRevisionHistory: (id, payload) =>
    id
      ? patch(`/api/v1/revision-history/${id}/`, payload)
      : post(`/api/v1/revision-history/`, payload),
  getRevisionHistory: id =>
    get(`/api/v1/revision-history/risk_assessment/${id}/`),

  addAttendance: payload => post(`/api/v1/attendance/`, payload),
  getAttendance: id => get(`/api/v1/attendance/risk_assessment/${id}/`),
  getEmployee: () => get(`/api/v1/attendance-user/`),
  getRASummary: ({riskAssessmentId, config}) => get(`/api/v1/summary/`, config),
  generateExcelId: payload => post(`/api/v1/generate-excel/`, payload),
  generateExcel: id => get(`/api/v1/generate-excel/unique-id/${id}/`),
  importEvent: payload => post(`/api/v1/import-event/`,payload)
};

const feedback = {
  sendFeedback: payload => post('/api/v1/feedback/', payload),
  getFeedback: config => get('/api/v1/feedback/', config),
  sendFeedbackReply: payload => post('/api/v1/feedback-reply/', payload),
};

const password = {
  changePassword: payload => post('/api/v1/change-password/', payload),
};

const termsConditions = {
  getTermsAndConditions: () => get('/api/v1/terms-and-conditions/'),
  getPrivacyPolicy: () => get('/api/v1/privacy-policy/'),
};

export const apiFunctions = {
  // getUser: (config) => get('https://jsonplaceholder.typicode.com/users',config),
  ...loginApi,
  ...passwordReset,
  ...userList,
  ...riskAssessments,
  ...riskAssessmentsCore,
  ...feedback,
  ...password,
  ...termsConditions,
};
