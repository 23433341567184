import { Modal } from "react-bootstrap"
import { assets } from "../../../../../../utils/assetsSrc"
import "./internalNotes.scss"
import TextArea from "../../../../../../components/form/textArea/TextArea"

export default function InternalNotes({ show, setShow, label, notes }) {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="internal-notes"
      centered
      size="lg"
      className="internal-notes-wrapper"
    >
      <Modal.Body>
        <div className="header">
          <p className="label mt-3">{label}</p>
          <img onClick={() => setShow(false)} src={assets.closeIcon} />
        </div>
        {notes.map(item => (
          <div className="internal-notes-form">
            <p className="title">{item.event_task__event__name}</p>
            <TextArea
              placeholder={"Custom Text"}
              label={"Note(Optional)"}
              name="notes"
              type="text"
              value={item.internal_note}
              disabled
            />
          </div>
        ))}
      </Modal.Body>
    </Modal>
  )
}
