import React, { useEffect, useState } from "react"
import "./assumptions.scss"
import AssumptionItem from "./assumtion-item"
import AddEditAssumption from "./add-edit-assumtion"
import { useDispatch } from "react-redux"
import {
  addAssumption,
  getAssumptions
} from "../../../../../redux/risk-assessment-core/riskAssessmentCoreSlice"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  getAssumptionData,
  getRiskAssessmentCoreLoadingState
} from "../../../../../redux/selectors/riskAssessmentCore"
import Loader from "../../../../../components/loader/loader"

const Assumptions = () => {
  const [showModel, setShowModel] = useState(false)
  const [id, setId] = useState()
  const [label, setLabel] = useState()
  const [value, setValue] = useState()

  const dispatch = useDispatch()

  const location = useLocation()
  const selectedRAId = location.state.selectedRiskAssessment.id
  const isLoading = useSelector(getRiskAssessmentCoreLoadingState())
  const assumptionsData = useSelector(getAssumptionData())

  useEffect(() => {
    dispatch(getAssumptions(selectedRAId))
  }, [])
  const handleSave = (id, value, label) => {
    setShowModel(true)
    setId(id)
    setValue(value)
    setLabel(label)
  }

  const saveAssumption = vals => {
    const payload = { ...vals }
    payload.risk_assessment = selectedRAId
    const id = assumptionsData?.id
    dispatch(
      addAssumption({
        id,
        payload,
        callback: result => {
          dispatch(getAssumptions(selectedRAId))
          setShowModel(false)
        }
      })
    )
  }
  return (
    <div className="assumptions-wrapper">
      <div className="heading">Assumptions:</div>
      <hr />
      <AssumptionItem
        label={"Assumed controls"}
        id={"assumed_controls"}
        handleSave={handleSave}
        value={assumptionsData?.assumed_controls}
      />
      <AssumptionItem
        label={"Features covered"}
        id={"features_covered"}
        handleSave={handleSave}
        value={assumptionsData.features_covered}
      />
      <AssumptionItem
        label={"Additional information:"}
        id={"additional_information"}
        handleSave={handleSave}
        value={assumptionsData.additional_information}
      />
      {label && (
        <AddEditAssumption
          id={id}
          label={label}
          data={value}
          saveAssumption={saveAssumption}
          show={showModel}
          setShow={setShowModel}
        />
      )}
      {isLoading && <Loader />}
    </div>
  )
}

export default Assumptions
