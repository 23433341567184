export const crumbLabel = {
  "product-line": "Product line",
  "product-models": "Models",
  "risk-assessments": "",
  "risk-assessment": "",
  "task-detail": "",
  "machine-limits": "Limits of Machine",
  "risk-assessment-info": "Risk Assessment Information",
  'assumptions': "Assumptions:",
  'revisionHistory': "Revision History",
  'attendanceRecord': "Attendance record",
  'summary':'Summary'

}

export const crumbHref = {
  "product-line": "/dashboard/assessment/product-line",
  "product-models": "/dashboard/assessment/product-models",
  "risk-assessments": "/dashboard/assessment/risk-assessments",
  "risk-assessment": "/dashboard/assessment/risk-assessment",
  "task-detail": "/dashboard/assessment/task-detail",
  "machine-limits": "/dashboard/assessment/machine-limits"
}

export const currentPath = {
  "product-models": "Models",
  "risk-assessments": ""
}
