import React, {useState} from 'react';
import {Image, Modal, Row} from 'react-bootstrap';
import {ImageList, ImageListItem} from '@mui/material';
import ReactPlayer from 'react-player';
import './task-media.scss';
import {assets} from '../../../../../../utils/assetsSrc';

export default function TaskMedia({show, setShow, medias}) {
  const [currentIndex, setIndex] = useState(0);

  const getImageType = item => {
    if (
      item.file_type === '.png' ||
      item.file_type === '.jpg' ||
      item.file_type === 'jpeg'
    ) {
      return item.media;
    } else if (item.file_type === '.pdf') {
      return assets.pdfIcon2;
    } else if (item.file_type === '.xlsx') {
      return assets.excelIcon2;
    } else {
      return assets.documentIcon;
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="task-media"
      centered
      size="lg"
      className="task-media-wrapper">
      <Modal.Body className="p-5 px-5 text-center">
        <div>
          <div className="header">
            <p className="label mt-3 mb-4">{'View Media'}</p>
            <img onClick={() => setShow(false)} src={assets.closeIcon} />
          </div>
          <ImageList
            sx={{width: 720, height: 450}}
            cols={3}
            gap={12}
            variant="quilted">
            {medias.map(item => (
              <div>
                <ImageListItem key={item.media}>
                  {item.file_type === '.mp4' || item.file_type === '.webm' ? (
                    <ReactPlayer
                      url={item.media}
                      playing={false}
                      controls
                      width={230}
                      height={200}
                      onClick={() => window.open(`${item.media}`, '_blank')}
                    />
                  ) : (
                    <img
                      srcSet={`${getImageType(item)}`}
                      src={`${item.media}`}
                      onClick={() => window.open(`${item.media}`, '_blank')}
                      style={{height: '200px', width: '200px'}}></img>
                  )}
                </ImageListItem>
                <div>{item.name}</div>
              </div>
            ))}
          </ImageList>
          <Row>
            {/* <div className="d-flex align-items-center p-0 justify-content-between">
              <div
                className="media-navigation-left"
                onClick={() => {
                  setIndex(currentIndex == 0 ? 0 : currentIndex - 1)
                }}
              />

              <div className="media-container">
                <Image
                  src={medias?.[currentIndex]?.media}
                  thumbnail
                  fluid
                  className="media"
                />
              </div>

              <div
                className="media-navigation-right"
                onClick={() => {
                  setIndex(
                    currentIndex == medias.length - 1
                      ? medias.length - 1
                      : currentIndex + 1
                  )
                }}
              />
            </div> */}
            {/* <img src = {medias?.[0]?.media }></img> */}
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}
