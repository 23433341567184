import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import Button from "../../../../../components/form/button/button"
import FileUpload from "../../../../file-upload/fileUpload"
import TextField from "../../../../../components/form/textfield/textfield"
import { Formik, Form, ErrorMessage, Field } from "formik"
import * as Yup from "yup"
import "./addEditProductModel.scss"
import MultiSelectDropdown from "../../../../../components/form/multiSelectDropdown/MultiSelectDropdown"
import { assets } from "../../../../../utils/assetsSrc"

export default function AddEditProductModel({
  label,
  show,
  setShow,
  initialFormData,
  onclickConfirm,
  productImg,
  action
}) {
  const [pmImage, setPmImage] = useState("")
  const [formErrors, setFormErrors] = useState({
    pmImage: ""
  })

  const { productLineName, productLineId, productModelName } = initialFormData

  const initialValues = {
    productLine: productLineName,
    productModelName: productModelName ?? ""
  }
  const validate = Yup.object({
    productModelName: Yup.string().required("Name is Required")
  })

  const handleFileUpload = file => {
    if (file) {
      setPmImage(file)
      setFormErrors({ ...formErrors, pmImage: "" })
    }
  }

  const handleSubmit = vals => {
    if (action !== "edit" && !pmImage) {
      setFormErrors({ ...formErrors, pmImage: "Product Model Image Required" })
      return
    }
    const payload = new FormData()
    payload.append("name", vals.productModelName)
    if (pmImage) payload.append("image", pmImage)
    payload.append("product", productLineId)
    onclickConfirm(payload)
  }
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="confirm-cancel"
      centered
      className="add-edit-product-model"
    >
      <Modal.Body className="p-5 px-5 text-center">
        <div className="header">
          <p className="label mt-3 mb-4">{label}</p>
          <img onClick={() => setShow(false)} src={assets.closeIcon} />
        </div>
        <div className="file-upload-container">
          <FileUpload
            handleFileUpload={handleFileUpload}
            productImg={productImg}
          />
          {formErrors.pmImage && (
            <div style={{ textAlign: "left", color: "red" }}>
              {formErrors.pmImage}
            </div>
          )}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {({ formik, isSubmitting, isValid, errors }) => (
            <Form>
              <div>
                <TextField
                  name="productLine"
                  type="text"
                  label="Name of product lines"
                  placeholder="Name of product line"
                  disabled
                />
              </div>
              <div className="user-access-list">
                <TextField
                  name="productModelName"
                  type="text"
                  label="Name of Model"
                  placeholder="Name of Model"
                />
              </div>
              <div className="action-buttons">
                <Button
                  type="button"
                  clickHandler={() => setShow(false)}
                  variant="secondary-button"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="submit-button">
                  {action === "edit" ? "Update" : "Create"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
