import React, {useEffect, useState} from 'react';
import './attendance-record.scss';
import Button from '../../../../../components/form/button/button';
import {Form, Formik} from 'formik';
import TextField from '../../../../../components/form/textfield/textfield';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  getAttendanceData,
  getEmployeeData,
  getRiskAssessmentCoreLoadingState,
} from '../../../../../redux/selectors/riskAssessmentCore';
import {useSelector} from 'react-redux';
import SingleSelectDropdown from '../../../../../components/form/singleSelectDropdown/singleSelectDropdown';
import {getUserRoleName, statusEnum} from '../../../../../utils/constants';
import AddManualEmployee from './add-manual-employee';
import {
  addAttendance,
  getAttendance,
  getEmployee,
} from '../../../../../redux/risk-assessment-core/riskAssessmentCoreSlice';
import Loader from '../../../../../components/loader/loader';
import Table from '../../../../../components/table/table';
import {changeDateFormat} from '../../../../../utils/DateUtils';
import * as Yup from 'yup';

const AttendanceRecord = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const selectedRAId = location.state.selectedRiskAssessment?.id;
  const isLoading = useSelector(getRiskAssessmentCoreLoadingState());
  const attendanceData = useSelector(getAttendanceData());
  const employeeData = useSelector(getEmployeeData());

  const [showModel, setShowModel] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState();

  useEffect(() => {
    dispatch(getAttendance(selectedRAId));
    dispatch(getEmployee());
  }, []);

  const getTotalTime = () => {
    const totalMins = attendanceData.reduce(
      (accumulator, currentValue) => accumulator + currentValue.duration,
      0,
    );

    var hours = Math.floor(totalMins / 60);
    var minutes = totalMins % 60;
    let time = '';
    if (hours != 0) time = `${hours} hour ${minutes} Min`;
    else time = `${minutes} Min`;

    return time;
  };

  const addManualEmployee = payload => {
    payload.risk_assessment = selectedRAId;

    dispatch(
      addAttendance({
        payload,
        callback: result => {
          dispatch(getAttendance(selectedRAId));
          setShowModel(false);
        },
      }),
    );
  };

  const initialValues = {
    date: '',
    duration: '',
    employee: '',
  };

  const validate = Yup.object({
    employee: Yup.string().required('Employee is Required'),
    date: Yup.string().required('Date is Required'),
    duration: Yup.string().required('Duration is Required'),
  });

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
    },
    {
      accessor: 'role',
      Header: 'Role',
      Cell: ({row}) =>
        row.original.role
          ? row.original.role == 2
            ? 'General'
            : 'Admin'
          : '-',
    },
    {
      accessor: 'job_title',
      Header: 'Job Title',
    },
    {
      accessor: 'email',
      Header: 'Email',
      Cell: ({row}) => row.original.email ?? '-',
    },
    {
      accessor: 'date',
      Header: 'Date',
      Cell: ({row}) =>
        changeDateFormat(row.original.date, 'YYYY-MM-DD', 'MM/DD/YYYY'),
    },
    ,
    {
      accessor: 'duration',
      Header: 'Duration',
      Cell: ({row}) => row.original.duration + ' Min',
    },
  ];

  const handleAddAttendance = (payload, {resetForm}) => {
    payload.risk_assessment = selectedRAId;
    payload.job_title = selectedEmployee.job_title ?? 'Consultant';
    payload.name = selectedEmployee.fullname;
    dispatch(
      addAttendance({
        payload,
        callback: result => {
          dispatch(getAttendance(selectedRAId));
          setShowModel(false);
        },
      }),
    );
    resetForm();
  };
  return (
    <div className="attendance-wrapper">
      <div className="d-flex justify-content-between align-items-center">
        <div className="heading">Employee</div>
        <span
          className="add-manual"
          onClick={() => {
            setShowModel(true);
          }}>
          Add Employee details manually
        </span>
      </div>
      <hr />

      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={handleAddAttendance}>
        {({formik, values, setFieldValue, isSubmitting, isValid, errors}) => (
          <Form>
            <div className="add-employee">
              <SingleSelectDropdown
                label={'Name'}
                listItems={employeeData}
                name="employee"
                value={values['employee']}
                handleSelectedUsersChange={val => {
                  setFieldValue('employee', val?.id);
                  setSelectedEmployee(val);
                }}
                showError={true}
              />

              <TextField
                name={'date'}
                type="date"
                label={'Date'}
                placeholder={'Date'}
                styles={{height: '56px'}}
              />
            </div>
            <div className="add-employee">
              <TextField
                name={'duration'}
                type="number"
                label={'Session duration (Min)'}
                placeholder={'Session duration (Min)'}
                min={1}
              />
              <Button type="submit" variant={'submit-button'}>
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <hr />
      <div className="d-flex justify-content-between align-items-center">
        <div className="total-session-duration"> List of attendees</div>
        <div className="total-session-duration">
          <div>Total work-hours</div>
          <div>{getTotalTime()}</div>
        </div>
      </div>

      <div className="attendance-table">
        <Table
          columns={columns}
          data={attendanceData}
          isLoading={false}
          height={'68vh'}
        />
      </div>
      {isLoading && <Loader />}
      <AddManualEmployee
        show={showModel}
        setShow={setShowModel}
        handleAdd={addManualEmployee}
      />
    </div>
  );
};

export default AttendanceRecord;
