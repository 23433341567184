import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import {
  createProductLine,
  createProductModel,
  createRiskAssessment,
  deleteProductLine,
  editProductLine,
  getProductLines
} from "../../../../../redux/risk-assessment/riskAssessmentSlice"
import { getproductLineStateSelector } from "../../../../../redux/selectors/riskAssessment"
import { assets } from "../../../../../utils/assetsSrc"
import ProductLineCard from "../../components/product-line-card/productLineCard"
import "./productLine.scss"
import { sessionAttributes, setItemInSession } from "../../utils/sessionStorage"
import Button from "../../../../../components/form/button/button"
import AddEditProductLine from "../../components/add-edit-product-line/addEditProductLine"
import AddEditProductModel from "../../components/add-edit-product-model/addEditProductModel"
import NewRiskAsessment from "../../components/new-risk-assessment/newRiskAsessment"
import { rbac } from "../../../../../utils/constants"
import { getLoginStateSelector } from "../../../../../redux/selectors/loginSelector"
import CustomSearch from "../../../../../components/custom-search/customSearch"
import Confirmation from "../../../../../components/confirmation/confirmation"

export default function ProductLine() {
  const [showaddEditProductLine, setShowaddEditProductLine] = useState(false)
  const [showEditProductLine, setShowEditProductLine] = useState(false)
  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false)
  const [showAddProductModel, setShowAddProductModel] = useState(false)
  const [productLineConfig, setProductLineConfig] = useState({
    params: { search: "" }
  })
  const productLineData = useSelector(getproductLineStateSelector())
  const [selectedProductLine, setSelectedProductLine] = useState({})
  const [showCreateNewRiskAssessment, setShowCreateNewRiskAssessment] =
    useState(false)
  const nav = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  let searchTimer

  const userRoleId = useSelector(getLoginStateSelector())?.loggedInUser?.role
  const addEditMachine = rbac.addnewMachine(userRoleId)

  useEffect(() => {
    getProductLineData()
  }, [])

  const getProductLineData = () => {
    dispatch(getProductLines(productLineConfig))
  }

  useEffect(() => {
    getProductLineData()
  }, [productLineConfig])

  const viewModelList = id => {
    const productLineId = id
    nav(`/dashboard/assessment/product-models?id=${productLineId}`)
  }

  const productLineHandler = (type, id) => {
    const selectedProductLine = productLineData.find(pl => pl.id === id)
    setSelectedProductLine(selectedProductLine)
    switch (type) {
      case "view":
        setItemInSession(
          sessionAttributes.selectedProductLine,
          JSON.stringify(selectedProductLine)
        )
        viewModelList(id)
      case "add":
        setShowAddProductModel(true)
      // case "edit":
      //   return
      //   setShowEditProductModel(true)
      default:
        return
    }
  }

  const handleAddProductLineConfirm = payload => {
    dispatch(
      createProductLine({
        payload,
        callback: response => {
          if (response.status === 201) {
            dispatch(getProductLines())
          }
          setShowaddEditProductLine(false)
        }
      })
    )
  }

  const handleEditProductLineConfirm = payload => {
    const id = selectedProductLine?.id
    dispatch(
      editProductLine({
        id,
        payload,
        callback: resp => {
          setShowEditProductLine(false)
          dispatch(getProductLines())
        }
      })
    )
  }

  const handleRiskAssessmentAction = payload => {
    dispatch(
      createRiskAssessment({
        payload,
        callback: resp => {
          setShowCreateNewRiskAssessment(false)
        }
      })
    )
  }

  const handleAddEditProductModelConfirm = payload => {
    dispatch(
      createProductModel({
        payload,
        callback: resp => {
          setShowAddProductModel(false)
        }
      })
    )
  }
  const handleProductLineDeleteConfirm = () => {
    const id = selectedProductLine?.id
    setShowConfirmationPopUp(false)
    dispatch(
      deleteProductLine({
        id,
        callback: resp => {
          dispatch(getProductLines())
        }
      })
    )
  }

  const handleInputChange = e => {
    clearTimeout(searchTimer)
    searchTimer = setTimeout(() => {
      const obj = { ...productLineConfig }
      obj.params.search = e.target.value
      setProductLineConfig(obj)
    }, 1000)
  }

  return (
    <div className="product-line-page-wrapper">
      <header>
        <div className={`product-line-header ${!addEditMachine && "product-line-general-header"}`}>
          <div>Product line</div>
          <div className="action-buttons">
            {rbac.addEditProductLine(userRoleId) && (
              <div>
                <Button
                  clickHandler={() => setShowaddEditProductLine(true)}
                  variant="secondary-button"
                >
                  Add Product Lines
                </Button>
              </div>
            )}
            {rbac.newRiskAssessment(userRoleId) && (
              <div>
                <Button
                  clickHandler={() => setShowCreateNewRiskAssessment(true)}
                  variant="submit-button"
                >
                  New Risk Assessment
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="pl-search">
          {/* <img src={assets.filterIcon} /> */}
          <CustomSearch
            onChange={handleInputChange}
            width="400px"
            type="text"
          />
        </div>
      </header>
      {/* <div>
                <button onClick={viewModelList}>view model</button>
            </div> */}
      <div className="product-line-list">
        {productLineData?.map(productLine => (
          <ProductLineCard
            key={productLine.id}
            id={productLine.id}
            name={productLine.name}
            image={productLine.image}
            addEditMachine={addEditMachine}
            clickHandler={productLineHandler}
            handleEdit={() => {
              setSelectedProductLine(productLine)
              setShowEditProductLine(true)
            }}
            handleDelete={() => {
              setSelectedProductLine(productLine)
              setShowConfirmationPopUp(true)
            }}
            // image={productLine.image}
          />
        ))}
      </div>
      {showaddEditProductLine && (
        <AddEditProductLine
          label="Add new product lines"
          show={showaddEditProductLine}
          setShow={setShowaddEditProductLine}
          onclickConfirm={handleAddProductLineConfirm}
          initialFormData={{}}
        />
      )}
      {showEditProductLine && (
        <AddEditProductLine
          label="Edit product lines"
          show={showEditProductLine}
          setShow={setShowEditProductLine}
          onclickConfirm={handleEditProductLineConfirm}
          initialFormData={{
            productLine: selectedProductLine.name,
            pImg: selectedProductLine?.image
          }}
          action={"edit"}
        />
      )}
      {showAddProductModel && (
        <AddEditProductModel
          label="Add New Model"
          show={showAddProductModel}
          setShow={setShowAddProductModel}
          initialFormData={{
            productLineName: selectedProductLine.name,
            productLineId: selectedProductLine.id
          }}
          onclickConfirm={handleAddEditProductModelConfirm}
        />
      )}

      {showCreateNewRiskAssessment && (
        <NewRiskAsessment
          label={"New Risk Assessment"}
          show={showCreateNewRiskAssessment}
          setShow={setShowCreateNewRiskAssessment}
          handleRiskAssessmentAction={handleRiskAssessmentAction}
        />
      )}
      {showConfirmationPopUp && (
        <Confirmation
          label={"Delete product line"}
          show={showConfirmationPopUp}
          setShow={setShowConfirmationPopUp}
          confirmationType={"typeDelete"}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          primaryButtonClickHandler={handleProductLineDeleteConfirm}
          secondaryButtonClickHandler={() => setShowConfirmationPopUp(false)}
        />
      )}
    </div>
  )
}
