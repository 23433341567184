import React, {useEffect, useState} from 'react';
import './event-details.scss';
import TaskInfo from './task-info';
import {Form, Formik} from 'formik';
import ProtectiveMeasure from './protective-measure';
import UnwantedEvent from './unwanted-event';
import Status from './status';
import Button from '../../../../../components/form/button/button';
import ControlSystem from './control-syste';
import RiskLowered from './risk-lowered';
import FinalRecommendation from './final-recommendation';
import ActionRequired from './action-require';
import EventAction from './event-action';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';
import {
  enumStatusId,
  eventDetailInfoIconEnum,
  getUserRoleName,
  likelihoodUnwantedEventEnum,
  severityUnwantedEventEnum,
  statusEnum,
  statusTypes,
  statusValues,
} from '../../../../../utils/constants';
import {
  getEventDetail,
  saveEventDetail,
  updateEventDetail,
  uploadMedia,
} from '../../../../../redux/risk-assessment-core/riskAssessmentCoreSlice';
import {useLocation, useNavigate} from 'react-router-dom';
import {allRoutes} from '../../../../../utils/routeConstants';
import Loader from '../../../../../components/loader/loader';
import {useSelector} from 'react-redux';
import {
  getEventDetailState,
  getRiskAssessmentCoreLoadingState,
} from '../../../../../redux/selectors/riskAssessmentCore';
import TaskMedia from '../task-details/task-media/task-media';
import { getLoginStateSelector } from '../../../../../redux/selectors/loginSelector';

const EventDetail = () => {
  const loginState = useSelector(getLoginStateSelector());
  const {loggedInUser} = loginState;
  const dispatch = useDispatch();
  const location = useLocation();
  const currentNavState = location.state;
  const selectedRiskAssessment = currentNavState.selectedRiskAssessment;
  const eventId = currentNavState.eventId;
  const selectedTask = currentNavState.selectedTask
  const selectedEventName = currentNavState.selectedEventName
  const nav = useNavigate();
  const isLoading = useSelector(getRiskAssessmentCoreLoadingState());
  //const eventDetail = useSelector(getEventDetailState())
  const [eventDetail, setEventDetail] = useState();
  const [showTaskMedia, setShowTaskMedia] = useState(false);
  const [eventMedia, setEventMedia] = useState([]);
  const [statusLabel, setStatusLabel] = useState('');
  const [likelihoodId, setLikelihoodId] = useState();
  const [severityId, setSeverityId] = useState();
  const [readOnly, setReadOnly] = useState(
    getUserRoleName(loggedInUser?.role) === 'general',
  );
  useEffect(() => {
    fetchEventDetail();
  }, []);
  useEffect(() => {
    // setStatusLabel(statusValues[eventDetail?.likelihood_unwanted_event??"2"][eventDetail?.severity_unwanted_event??"1"])
    setStatusLabel(statusTypes[eventDetail?.risk_ranking_consideration]);
    setLikelihoodId(
      eventDetail?.likelihood_unwanted_event == 1
        ? 2
        : eventDetail?.likelihood_unwanted_event,
    );
    setSeverityId(eventDetail?.severity_unwanted_event);
  }, [eventDetail]);
  const fetchEventDetail = () => {
    const payload = {
      eventId: eventId,
      config: {},
    };
    dispatch(
      getEventDetail({
        payload,
        callback: resp => {
          setEventDetail(resp);
          setEventMedia(resp.media_details);
        },
      }),
    );
  };

  const initialValues = {
    inherently_measures: eventDetail?.inherently_measures ?? '',
    safeguarding: eventDetail?.safeguarding ?? '',
    info_for_use: eventDetail?.info_for_use ?? '',
    cntrl_hazard: eventDetail?.cntrl_hazard ?? 0,
    cntrl_motion: eventDetail?.cntrl_motion ?? 0,
    safety_note: eventDetail?.safety_note ?? '',
    // likelihood_unwanted_event: eventDetail?.likelihood_unwanted_event
    //   ? likelihoodUnwantedEventEnum.find(
    //       item => item.id == eventDetail?.likelihood_unwanted_event
    //     )??likelihoodUnwantedEventEnum[0]
    //   : likelihoodUnwantedEventEnum[0],
    likelihood_unwanted_event: likelihoodUnwantedEventEnum.find(
      item => item.id == eventDetail?.likelihood_unwanted_event,
    ),
    likelihood_note: eventDetail?.likelihood_note ?? '',
    severity_unwanted_event: severityUnwantedEventEnum.find(
      item => item.id == eventDetail?.severity_unwanted_event,
    ),
    // severity_unwanted_event: eventDetail?.severity_unwanted_event
    //   ? severityUnwantedEventEnum.find(
    //       item => item.id == eventDetail?.severity_unwanted_event,
    //     )
    //   : severityUnwantedEventEnum[0],
    severity_note: eventDetail?.severity_note ?? '',
    risk_ranking_consideration: eventDetail?.risk_ranking_consideration
      ? statusEnum.find(
          item => item.id == eventDetail?.risk_ranking_consideration,
        )
      : statusEnum[0],
    risk_ranking_note: eventDetail?.risk_ranking_note ?? '',
    risk_low_accpt_level: eventDetail?.risk_low_accpt_level ?? 0,
    risk_low_accpt_level_note: eventDetail?.risk_low_accpt_level_note ?? '',
    additional_hazards: eventDetail?.additional_hazards ?? 0,
    new_task: eventDetail?.new_task ? [...eventDetail?.new_task] : [],
    internal_note: eventDetail?.internal_note ?? '',
    final_note: eventDetail?.final_note ?? '',
    action_required_by: eventDetail?.action_required_by
      ? [...eventDetail?.action_required_by]
      : [],
    action_required_text: eventDetail?.action_required_text ?? '',
    event_status: eventDetail?.event_status ?? null,
    removed_notes: eventDetail?.removed_notes ?? '',
    // assessed_by: null,
    parent_risk_assessment: selectedRiskAssessment.id,
    event_task: eventId,
  };
  const validate = Yup.object({
    inherently_measures: Yup.string().required(
      'Inherently Safe Design Measures is Required',
    ),
    safeguarding: Yup.string().required('Safeguarding is Required'),
    info_for_use: Yup.string().required('Information for Use is Required'),
  });

  const handleSubmit = vals => {
    console.log(enumStatusId[statusLabel]);
    const payload = {...vals};
    payload.likelihood_unwanted_event = vals.likelihood_unwanted_event?.id;
    payload.severity_unwanted_event = vals.severity_unwanted_event?.id;
    payload.risk_ranking_consideration = enumStatusId[statusLabel];
    payload.media = eventMedia?.map(media => media.id);

    console.log(JSON.stringify(payload));

    if (eventDetail?.id) {
      dispatch(
        updateEventDetail({
          id: eventDetail?.id,
          payload,
          callback: goBack,
        }),
      );
    } else {
      dispatch(
        saveEventDetail({
          payload,
          callback: goBack,
        }),
      );
    }
  };

  const goBack = () => {
    nav(allRoutes.dashboardRoutes.assessmentRoutes.taskDetails, {
      state: location.state,
    });
  };

  const handleMediaAdd = e => {
    const files = Array.from(e.target.files);
    const payload = new FormData();
    files.forEach(file => {
      payload.append('media', file);
    });
    dispatch(
      uploadMedia({
        payload,
        callback: resp => {
          setEventMedia([...eventMedia, ...resp]);
        },
      }),
    );
  };

  useEffect(() => {
    if (likelihoodId && severityId)
      setStatusLabel(statusValues[likelihoodId][severityId]);
  }, [likelihoodId, severityId]);

  const onHandleChange = (name, val) => {
    {
      name === 'likelihood_unwanted_event'
        ? setLikelihoodId(val)
        : setSeverityId(val);
    }
  };
  return (
    <div className="event-details-wrapper">
      <header>
        <TaskInfo
          handleMediaAdd={handleMediaAdd}
          setShowTaskMedia={setShowTaskMedia}
          readOnly={readOnly}
        />
      </header>
      {eventDetail && (
        <div className="event-info-wrapper">
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={handleSubmit}>
            {({
              formik,
              values,
              setFieldValue,
              isSubmitting,
              isValid,
              errors,
            }) => (
              <Form>
                <ProtectiveMeasure setFieldValue={setFieldValue} readOnly={readOnly}/>
                <div className="heading">Functional safety:</div>
                <ControlSystem
                  setFieldValue={setFieldValue}
                  values={values}
                  showInfoIcon={false}
                  readOnly={readOnly}
                />
                <UnwantedEvent
                  setFieldValue={setFieldValue}
                  name={'likelihood_unwanted_event'}
                  title={'Likelihood of potential unwanted event'}
                  description={
                    likelihoodId
                      ? `'The team has determined that based on the inherently safe design measures, safeguarding, and information for use, the likelihood of "${selectedEventName}" during "${selectedTask.name}" is ${values['likelihood_unwanted_event']?.name}'`
                      : ''
                  }
                  placeholder={'Select Option'}
                  items={likelihoodUnwantedEventEnum}
                  noteName={'likelihood_note'}
                  values={values}
                  showInfoIcon={true}
                  infoIconLabel={eventDetailInfoIconEnum.likelihood}
                  onHandleChange={onHandleChange}
                  readOnly={readOnly}
                />
                <UnwantedEvent
                  setFieldValue={setFieldValue}
                  name={'severity_unwanted_event'}
                  title={'Worst credible severity of potential unwanted event'}
                  description={
                    severityId
                      ? `'The team has determined that based on the inherently safe design measures, safeguarding, and information for use, the worst credible severity of "${selectedEventName}" during "${selectedTask.name}" is ${values['severity_unwanted_event'].name}'`
                      : ''
                  }
                  placeholder={'Select Option'}
                  items={severityUnwantedEventEnum}
                  noteName={'severity_note'}
                  values={values}
                  showInfoIcon={true}
                  infoIconLabel={
                    eventDetailInfoIconEnum.worst_credible_severity
                  }
                  onHandleChange={onHandleChange}
                  readOnly={readOnly}
                />

                <Status
                  setFieldValue={setFieldValue}
                  values={values}
                  statusValues={statusLabel}
                  showInfoIcon={false}
                  readOnly={readOnly}
                />
                <RiskLowered
                  setFieldValue={setFieldValue}
                  values={values}
                  showInfoIcon={true}
                  eventName={selectedEventName}
                  taskName={selectedTask.name}
                  readOnly={readOnly}
                />

                <FinalRecommendation
                  setFieldValue={setFieldValue}
                  values={values}
                  readOnly={readOnly}
                />

                <ActionRequired
                  setFieldValue={setFieldValue}
                  actionRequireValue={values.action_required_by}
                  readOnly={readOnly}
                />

                <EventAction
                  setFieldValue={setFieldValue}
                  eventStatus={values.event_status}
                  noteValue={values.removed_notes}
                  readOnly={readOnly}
                />
                {!readOnly && (
                <div className="action-buttons">
                  <Button type="submit" variant="submit-button">
                    Save
                  </Button>
                  {/* <Button type="submit" variant="submit-button">
                    Save & continue
                  </Button> */}
                </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      )}

      {isLoading && <Loader />}
      {showTaskMedia && (
        <TaskMedia
          medias={eventMedia}
          show={showTaskMedia}
          setShow={() => setShowTaskMedia(false)}
        />
      )}
    </div>
  );
};

export default EventDetail;
