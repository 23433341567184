import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { assets } from '../../../../../utils/assetsSrc'
import './share.scss'
import { useLocation } from 'react-router-dom'
import { allRoutes } from '../../../../../utils/routeConstants'
import Button from '../../../../../components/form/button/button'

export default function Share({ show, setShow, riskAssessmentId, linkName }) {
    const [isLinkCopied, setIsLinkCopied] = useState(false)
    const location = useLocation()
    let copyTimer
    const copyLinkAddress = `${window.location.origin}/share-risk-assessment/${riskAssessmentId}`
    const copyLink = () => {
        navigator.clipboard.writeText(copyLinkAddress)
        setIsLinkCopied(true)
    }
    useEffect(() => {
        clearTimeout(copyTimer)
        copyTimer = setTimeout(() => {
            setIsLinkCopied(false)
        }, 2000)
    }, [isLinkCopied])
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="confirm-cancel"
            centered
            size='md'
            className="share-risk-assessment">
            <Modal.Body className="p-5 px-5 text-center">
                <div className='header'>
                    <p className="label mt-3 mb-4">{'Share Assessment'}</p>
                    <img onClick={() => setShow(false)} src={assets.closeIcon} />
                </div>
                {isLinkCopied && <div className='link-copied'>
                    Copied!!
                </div>}
                <div className='input-box'>
                    <label htmlFor="note-input">Link</label>
                    <div className='box'>
                        <a href={copyLinkAddress} target='blank'>{linkName}</a>
                        <img onClick={copyLink} className='copy-image' src={assets.copyIcon} />
                    </div>
                </div>
                <div className='copy-button'>
                    <Button variant={'submit-button'} clickHandler={copyLink}>Copy</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
