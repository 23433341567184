import {call, put, take, takeLatest} from 'redux-saga/effects';
import {apiFunctions} from '../../api/functions';
import {errorHandler} from '../../utils/errorHandling';
import {
  // get Product lines
  getProductLinesSuccess,
  getProductLinesFailure,
  // get product models
  getProductModelsSuccess,
  getProductModelsFailure,
  // create product models
  createProductModelSuccess,
  createProductModelFailure,
  // create Product Line
  createProductLineSuccess,
  createProductLineFailure,
  // create Risk Assessment
  createRiskAssessmentSuccess,
  createRiskAssessmentFailure,
  // fetch Risk Assessments
  getRiskAssessmentsSuccess,
  getRiskAssessmentsFailure,
  deleteRiskAssessmentSuccess,
  deleteRiskAssessmentFailure,
  editRiskAssessmentFailure,
  editRiskAssessmentSuccess,
  getRiskAssessmentsByIdSuccess,
  getRiskAssessmentsByIdFailure,
  deletedRiskAssessmentSuccess,
  deletedRiskAssessmentFailure,
  restoreRiskAssessmentSuccess,
  restoreRiskAssessmentFailure,
  editProductModelSuccess,
  editProductModelFailure,
  deleteProductModelSuccess,
  deleteProductModelFailure,
  editProductLineSuccess,
  editProductLineFailure,
  deleteProductLineSuccess,
  deleteProductLineFailure,
  hardDeleteRiskAssessmentSuccess,
  hardDeleteRiskAssessmentFailure,
  getRiskAssessmentInfoSuccess,
  getRiskAssessmentInfofailure,
  getAllRiskAssessmentsSuccess,
  getAllRiskAssessmentsFailure,
} from './riskAssessmentSlice';

//  ---------------------------- Product Line and Product Models --------------------------

// API Functions

const getProductLinesFromAPI = apiFunctions.getProductLines;
const createProductLineFromAPI = apiFunctions.createProductLine;
const editProductLineFromAPI = apiFunctions.editProductLine;
const deleteProductLineFromAPI = apiFunctions.deleteProductLine;

const getproductModelsFromAPI = apiFunctions.getProductLineModels;
const createNewProductModelFromAPI = apiFunctions.createProductModel;
const editModelFromAPI = apiFunctions.editProductModel;
const deleteModelFromAPI = apiFunctions.deleteProductModel;

//Risk Assessment
const createNewRiskAssessmentFromAPI = apiFunctions.createRiskAssessment;
const getAllRiskAssessmentsFromAPI = apiFunctions.getRiskAssessments;
const getAllRiskAssessmentsByIdFromAPI = apiFunctions.getRiskAssessmentById;
const deleteRiskAssessmentFromAPI = apiFunctions.deleteRiskAssessment;
const editARiskAssessmentFromAPI = apiFunctions.editRiskAssessment;
const deletedRiskAssessmentFromAPI = apiFunctions.deletedRiskAssessment;
const restoreRiskAssessmentFromAPI = apiFunctions.restoreRiskAssessment;
const hardDeleteRiskAssessmentFromAPI = apiFunctions.hardDeleteRiskAssessment;
const getRiskAssessmentInfoFromAPI = apiFunctions.getRiskAssessmentInfo;

// Product Lines get starts
async function productLines(payload) {
  return await getProductLinesFromAPI(payload);
}

function* getProductLinesHandler({payload}) {
  try {
    const result = yield call(productLines, payload);
    yield put(getProductLinesSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getProductLinesFailure(err));
  }
}
// Product Lines get ends

// Product Line Post starts
async function createProductLines(payload) {
  return await createProductLineFromAPI(payload);
}

function* createProductLineHandler({payload}) {
  try {
    const result = yield call(createProductLines, payload.payload);
    payload.callback(result);
    yield put(createProductLineSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(createProductLineFailure(err));
  }
}

// Edit Product line
async function editProductLine(id, payload) {
  return await editProductLineFromAPI(id, payload);
}

function* editProductLineHandler({payload}) {
  try {
    const result = yield call(editProductLine, payload.id, payload.payload);
    payload.callback(result);
    yield put(editProductLineSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(editProductLineFailure(err));
  }
}

// delete Product model Post starts
async function deleteProductLine(id) {
  return await deleteProductLineFromAPI(id);
}

function* deleteProductLineHandler({payload}) {
  try {
    const result = yield call(deleteProductLine, payload.id);
    payload.callback(result);
    yield put(deleteProductLineSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(deleteProductLineFailure(err));
  }
}

// Product Line Post ends

export function* productLinesWatcher() {
  yield takeLatest('riskAssessment/getProductLines', getProductLinesHandler);
  yield takeLatest(
    'riskAssessment/createProductLine',
    createProductLineHandler,
  );
  yield takeLatest('riskAssessment/editProductLine', editProductLineHandler);
  yield takeLatest(
    'riskAssessment/deleteProductLine',
    deleteProductLineHandler,
  );
}

// Product line models

// get models
async function productModels(payload) {
  return await getproductModelsFromAPI(payload);
}

function* getProductModels({payload}) {
  try {
    const result = yield call(productModels, payload);
    yield put(getProductModelsSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getProductModelsFailure(err));
  }
}

// create models
async function createNewProductModel(payload) {
  return await createNewProductModelFromAPI(payload);
}

function* createProductModel({payload}) {
  try {
    const result = yield call(createNewProductModel, payload.payload);
    payload.callback(result);
    yield put(createProductModelSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(createProductModelFailure(err));
  }
}

// Edit Product model
async function editProductModel(id, payload) {
  return await editModelFromAPI(id, payload);
}

function* editProductModelHandler({payload}) {
  try {
    const result = yield call(editProductModel, payload.id, payload.payload);
    payload.callback(result);
    yield put(editProductModelSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(editProductModelFailure(err));
  }
}

// delete Product model Post starts
async function deleteProductModel(id) {
  return await deleteModelFromAPI(id);
}

function* deleteProductModelHandler({payload}) {
  try {
    const result = yield call(deleteProductModel, payload.id);
    payload.callback(result);
    yield put(deleteProductModelSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(deleteProductModelFailure(err));
  }
}

export function* productModelsWatcher() {
  yield takeLatest('riskAssessment/getProductModels', getProductModels);
  yield takeLatest('riskAssessment/createProductModel', createProductModel);
  yield takeLatest('riskAssessment/editProductModel', editProductModelHandler);
  yield takeLatest(
    'riskAssessment/deleteProductModel',
    deleteProductModelHandler,
  );
}

// --------------------------- Risk Assesment CRUD -----------------------------

// create risk assessment
async function createNewRiskAssessment(payload) {
  return await createNewRiskAssessmentFromAPI(payload);
}

function* createRiskAssessment({payload}) {
  try {
    const result = yield call(createNewRiskAssessment, payload.payload);
    payload.callback(result);
    yield put(createRiskAssessmentSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(createRiskAssessmentFailure(err));
  }
}

// edit risk assessment
async function editARiskAssessment(id, payload) {
  return await editARiskAssessmentFromAPI(id, payload);
}

function* editRiskAssessment({payload}) {
  try {
    const result = yield call(editARiskAssessment, payload.id, payload.payload);
    payload.callback(result);
    yield put(editRiskAssessmentSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(editRiskAssessmentFailure(err));
  }
}

// get Risk Assessments
async function getAllRiskAssessments(payload) {
  const obj = {
    productLineId: payload.productLineId || '',
    modelId: payload.modelId || '',
  };
  return await getAllRiskAssessmentsFromAPI(obj, payload.config);
}

function* getRiskAssessments({payload}) {
  try {
    const result = yield call(getAllRiskAssessments, payload);
    yield put(getRiskAssessmentsSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getRiskAssessmentsFailure(err));
  }
}
//get prduct line all riskAssessment
function* getProductLineRiskAssessments({payload}) {
  try {
    const result = yield call(getAllRiskAssessments, payload);
    yield put(getAllRiskAssessmentsSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getAllRiskAssessmentsFailure(err));
  }
}
// get Risk Assessment By Id
async function getAllRiskAssessmentsById(payload) {
  return await getAllRiskAssessmentsByIdFromAPI(payload);
}

function* getRiskAssessmentsById({payload}) {
  try {
    const result = yield call(getAllRiskAssessmentsById, payload.id);
    payload.callback(result);
    yield put(getRiskAssessmentsByIdSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getRiskAssessmentsByIdFailure(err));
  }
}

// delete risk assessment
async function deleteARiskAssessment(id) {
  return await deleteRiskAssessmentFromAPI(id);
}

function* deleteRiskAssessment({payload}) {
  try {
    const result = yield call(deleteARiskAssessment, payload.id);
    payload.callback();
    yield put(deleteRiskAssessmentSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(deleteRiskAssessmentFailure(err));
  }
}

// deleted risk assessment
async function deletedARiskAssessment(payload) {
  return await deletedRiskAssessmentFromAPI(payload);
}

function* deletedRiskAssessment({payload}) {
  try {
    const result = yield call(deletedARiskAssessment, payload);
    yield put(deletedRiskAssessmentSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(deletedRiskAssessmentFailure(err));
  }
}

// restore risk assessment
async function restoreARiskAssessment(id) {
  return await restoreRiskAssessmentFromAPI(id);
}

function* restoreRiskAssessment({payload}) {
  try {
    const result = yield call(restoreARiskAssessment, payload.id);
    payload.callback();
    yield put(restoreRiskAssessmentSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(restoreRiskAssessmentFailure(err));
  }
}

// permanently delete risk assessment
async function hardDeleteRiskAssessmentHandler(id) {
  return await hardDeleteRiskAssessmentFromAPI(id);
}

function* hardDeleteRiskAssessment({payload}) {
  try {
    const result = yield call(hardDeleteRiskAssessmentHandler, payload.id);
    payload.callback();
    yield put(hardDeleteRiskAssessmentSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(hardDeleteRiskAssessmentFailure(err));
  }
}

async function getRiskAssessmentInfoHandler(payload) {
  return await getRiskAssessmentInfoFromAPI(payload);
}

function* getRiskAssessmentInfo({payload}) {
  try {
    const result = yield call(getRiskAssessmentInfoHandler, payload);
    yield put(getRiskAssessmentInfoSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getRiskAssessmentInfofailure(err));
  }
}

export function* riskAssessmentWatcher() {
  yield takeLatest('riskAssessment/createRiskAssessment', createRiskAssessment);
  yield takeLatest('riskAssessment/getRiskAssessments', getRiskAssessments);
  yield takeLatest(
    'riskAssessment/getAllRiskAssessments',
    getProductLineRiskAssessments,
  );

  yield takeLatest(
    'riskAssessment/getRiskAssessmentsById',
    getRiskAssessmentsById,
  );
  yield takeLatest('riskAssessment/deleteRiskAssessment', deleteRiskAssessment);
  yield takeLatest('riskAssessment/editRiskAssessment', editRiskAssessment);
  yield takeLatest(
    'riskAssessment/deletedRiskAssessment',
    deletedRiskAssessment,
  );
  yield takeLatest(
    'riskAssessment/restoreRiskAssessment',
    restoreRiskAssessment,
  );
  yield takeLatest(
    'riskAssessment/hardDeleteRiskAssessment',
    hardDeleteRiskAssessment,
  );
  yield takeLatest(
    'riskAssessment/getRiskAssessmentInfo',
    getRiskAssessmentInfo,
  );

  // yield takeLatest('riskAssessment/createProductModel', createProductModel)
}
