import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {assets} from '../../../../../utils/assetsSrc';
import TextField from '../../../../../components/form/textfield/textfield';
import MultiSelectDropdown from '../../../../../components/form/multiSelectDropdown/MultiSelectDropdown';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import SingleSelectDropdown from '../../../../../components/form/singleSelectDropdown/singleSelectDropdown';
import Button from '../../../../../components/form/button/button';
import {useSelector} from 'react-redux';
import {eventRowInfoIconEnum} from '../../../../../utils/constants';
import {
  getAllRiskAssessmentStateSelector,
  getRiskAssessmentStateSelector,
} from '../../../../../redux/selectors/riskAssessment';
import {
  getUserRoleName,
  lifeCyclePhasesEnum,
  riskAssessmentAccessIds,
  riskAssessmentAccessNames,
  taskFrequencyEnum,
} from '../../../../../utils/constants';
import {apiFunctions} from '../../../../../api/functions';
import {Checkbox} from '@mui/material';
import './task-details.scss';
import ViewInfo from '../../components/view-info/view-info';
import {getLoginStateSelector} from '../../../../../redux/selectors/loginSelector';

export default function TaskInfo({taskData, handleTaskCreation}) {
  const loginState = useSelector(getLoginStateSelector());
  const {loggedInUser} = loginState;
  const riskAssessmentState = useSelector(getAllRiskAssessmentStateSelector());
  const [riskAssesmentsList, setRiskAssesmentsList] =
    useState(riskAssessmentState);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [editEnabled, setEditEnabled] = useState(
    getUserRoleName(loggedInUser?.role) === 'admin',
  );
  const [taskAccess, setTaskAccess] = useState(true);
  const [taskNote, setTaskNote] = useState(taskData.note || '');
  const [showNoteInput, setShowNoteInput] = useState(
    taskData?.note?.length > 0,
  );
  const initialValuesObj = {
    taskName: taskData.name,
    riskAssessmentList: taskData.risk_assessment_detail,
    taskDetails: taskData.details,
    lifeCyclePhases: lifeCyclePhasesEnum.find(
      phase => phase.id === taskData.life_cycle_phase,
    ),
    //subCategories: { id: taskData.sub_categories, name: taskData.sub_category_name },
    task_sub_categories: taskData.task_sub_categories,
    taskFrequency: taskFrequencyEnum.find(
      frequency => frequency.id === taskData.frequency,
    ),
    liveWork: taskData.live_work,
  };
  //const [initialValues, setInitialValues] = useState(initialValuesObj);

  const validate = Yup.object({
    riskAssessmentList: Yup.array().min(1,'Risk assessment is required'),
    taskName: Yup.string().required('Task Name is Required'),
    taskDetails: Yup.string().required('Task Details is Required'),
   // task_sub_categories: Yup.string().required('Task subcategory is Required')
  });

  useEffect(() => {
    setRiskAssesmentsList(riskAssessmentState);
    //getSubCategories();
  }, []);

  const getSubCategories = () => {
    apiFunctions.getSubCategoriesForTask().then(resp => {
      if (resp.status === 200 && resp.data.length) {
        setSubCategoriesList(resp.data);
      }
    });
  };

  const handleSubmit = vals => {
    const taskPayload = {
      name: vals.taskName || '',
      details: vals.taskDetails || '',
      risk_assessment: vals.riskAssessmentList.map(el => el.id) || [],
      life_cycle_phase: vals.lifeCyclePhases.id || '',
      //sub_categories: vals.subCategories.id || 0,
      task_sub_categories: vals.task_sub_categories || ' ',
      frequency: vals.taskFrequency.id,
      access: taskAccess ? 1 : 2,
      live_work: vals.liveWork || false,
      note: taskNote,
    };
    // setEditEnabled(false)
    handleTaskCreation(taskData.id, taskPayload);
  };

  return (
    <div className="task-info-wrapper">
      <Formik
        initialValues={initialValuesObj}
        validationSchema={validate}
        enableReinitialize
        onSubmit={handleSubmit}>
        {({formik, values, setFieldValue, isSubmitting, isValid, errors}) => (
          <Form>
            <div
              className={`risk-assessment-multiselect ${
                !editEnabled ? 'disable-field' : ''
              }`}>
              <MultiSelectDropdown
                label={'Risk assessment'}
                listItems={riskAssesmentsList}
                name="riskAssessmentList"
                value={values['riskAssessmentList']}
                renderChipsInside={true}
                handleSelectedUsersChange={val =>
                  setFieldValue('riskAssessmentList', val)
                }
                showError={true}
              />
            </div>
            <div>
              <TextField
                name="taskName"
                type="text"
                label="Task name"
                maxLength={100}
                disabled={!editEnabled}
                placeholder="Risk Assessment Task"
              />
            </div>
            <div>
              <TextField
                name="taskDetails"
                type="text"
                label="Task details"
                disabled={!editEnabled}
                placeholder="Enter Task Details"
                isMultiline={true}
                textAreaStyle={{height:100}}
                styles={{height:100}}
              />
            </div>
            <div className="more-task-details">
            <div
              className={`${
                !editEnabled ? 'disable-field' : ''
              }`}>
              <SingleSelectDropdown
                label={'Life cycle phases'}
                listItems={lifeCyclePhasesEnum}
                name="lifeCyclePhases"
                value={values['lifeCyclePhases']}
                handleSelectedUsersChange={val => {
                  setFieldValue('lifeCyclePhases', val);
                }}
              />
              </div>
              {/* <SingleSelectDropdown label={'Add task subcategories'} listItems={subCategoriesList} name='subCategories'
                                value={values['subCategories']} handleSelectedUsersChange={(val) => { setFieldValue('subCategories', val) }} /> */}
              <div className="mb-2">
                <TextField
                  name="task_sub_categories"
                  type="text"
                  disabled={!editEnabled}
                  label="Task subcategories"
                  placeholder="Add task subcategories"
                  styles={{height:56}}
                />
              </div>
              <div
              className={`${
                !editEnabled ? 'disable-field' : ''
              }`}>
              <SingleSelectDropdown
                label={'Task frequency'}
                listItems={taskFrequencyEnum}
                name="taskFrequency"
                value={values['taskFrequency']}
                handleSelectedUsersChange={val => {
                  setFieldValue('taskFrequency', val);
                }}
              />
            </div>
            </div>
            <div className="action-row">
              <div style={{display: 'flex', flex: '1', alignItems: 'center'}}>
                <div className="live-work">
                  <Checkbox
                    checked={values['liveWork']}
                    onChange={e => setFieldValue('liveWork', e.target.checked)}
                    inputProps={{'aria-label': 'controlled'}}
                    disabled={!editEnabled}
                  />
                  Live work
                </div>

                <div className="info-events">
                  <ViewInfo label={eventRowInfoIconEnum.live_work} />
                </div>
                {/* !taskNote.length|| */}
                {editEnabled && (
                  <>
                    {!showNoteInput ? (
                      <span
                        style={{marginLeft: '20px'}}
                        onClick={() => setShowNoteInput(true)}>
                        <img src={assets.addFilled} />
                        <span
                          style={{marginLeft: '5px'}}
                          className="add-note-text">
                          Add note
                        </span>
                      </span>
                    ) : (
                      <div className="note-row">
                        <label htmlFor="note-input">Note (optional)</label>
                        <input
                          id="note-input"
                          value={taskNote}
                          onChange={e => setTaskNote(e.target.value)}
                          type="text"
                        />
                        <img
                          className="close-icon"
                          onClick={() => setShowNoteInput(false)}
                          src={assets.closeIcon}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="action-buttons">
                {/* {!editEnabled && <Button type='button' variant='secondary-button' clickHandler={() => setEditEnabled(true)}>Edit</Button>} */}
                {editEnabled && (
                  <Button type="submit" variant="submit-button">
                    Save
                  </Button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
