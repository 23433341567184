import React, { useState } from "react"
import "./event-details.scss"
import SingleSelectDropdown from "../../../../../components/form/singleSelectDropdown/singleSelectDropdown"
import AddNote from "../../components/add-note/add-note"
import ViewInfo from "../../components/view-info/view-info"
import { eventDetailInfoIconEnum } from "../../../../../utils/constants"

const UnwantedEvent = ({
  title,
  description,
  placeholder,
  items,
  name,
  setFieldValue,
  noteName,
  values,
  showInfoIcon,
  infoIconLabel,
  onHandleChange,
  readOnly
}) => {
  const [showNoteInput, setShowNoteInput] = useState(values[noteName]?.length>0)

  return (
    <div>
      <div className="row1">
        <div className="label">{title}</div>
        <div className="info-icon-left">
          {showInfoIcon ? <ViewInfo label={infoIconLabel}/> : <></>}
        </div>
        <div
              className={`single-selection-dropdown ${
                readOnly ? 'disable-field' : ''
              }`}>
          <SingleSelectDropdown
            label={placeholder}
            listItems={items}
            name={name}
            value={values[name]}
            handleSelectedUsersChange={val => {
              onHandleChange(name, val?.id)
              setFieldValue(name, val)
            }}
          />
        </div>
      </div>
      <div className="heading">{description}</div>
      <AddNote
        showNoteInput={showNoteInput}
        setShowNoteInput={setShowNoteInput}
        name={noteName}
        value={values[noteName]}
        setFiledValue={setFieldValue}
        readOnly={readOnly}
      />
      <hr />
    </div>
  )
}

export default UnwantedEvent
