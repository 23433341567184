import { Formik, Form } from "formik"
import { Modal } from "react-bootstrap"
import TextField from "../../components/form/textfield/textfield"
import Button from "../../components/form/button/button"
import { assets } from "../../utils/assetsSrc"
import * as Yup from 'yup';
import { useDispatch } from "react-redux"
import { sendFeedbackReply } from "../../redux/feedback/feedbackSlice"
import "./feedback.scss"

export default function ReplyFeedback({ label, show, setShow, prefilledData }) {

  const dispatch = useDispatch()
  const messageInputStyle = {
    height: 88,
    marginTop: 20
  }

  const initialValues = {
    email: prefilledData.email,
    subject: prefilledData.subject,
    message: ""
  }

  const validate = Yup.object({
    message: Yup.string().required('Message is required')
  })

  const handleSubmit = values => {
    const payload = {
      email: values.email,
      subject: values.subject,
      text: values.message
    }
    dispatch(sendFeedbackReply(payload))
    setShow(false)
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="User Feedback"
      centered
      className="reply-feedback"
    >
      <Modal.Body className="p-5 px-5 text-center">
        <div className="header">
          <p className="label mt-3 mb-4">{label}</p>
          <img onClick={() => setShow(false)} src={assets.closeIcon} />
        </div>
        <Formik
         initialValues={initialValues} 
         onSubmit={handleSubmit}
         validationSchema={validate}
         >
          {formik => (
            <Form>
              <div className="feedback-form mt-2">
                <TextField
                  type="text"
                  label="Email ID"
                  name="email"
                  placeholder="Enter email"
                  disabled
                />
                <TextField
                  type="text"
                  label="Subject"
                  name="subject"
                  placeholder="Enter Subject"
                  disabled
                />
                <TextField
                  type="text"
                  label="Message"
                  name="message"
                  placeholder="Enter Message"
                  styles={messageInputStyle}
                  isMultiline={true}
                />
                <div className="action-buttons mt-10">
                  <Button
                    type="button"
                    clickHandler={() => setShow(false)}
                    variant="secondary-button"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="submit-button">
                    Send Reply
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
