import TextField from "../../../../../components/form/textfield/textfield"
import CheckBoxWithLabel from "./check-box-with-label"
import React from "react"
import "./event-details.scss"
const ActionRequired = ({ setFieldValue, actionRequireValue, readOnly }) => {
  const handleOnChange = (value, isChecked) => {
    if (isChecked) {
      actionRequireValue.push(value)
      setFieldValue("action_required_by", [...actionRequireValue])
    } else {
      const filteredData = actionRequireValue.filter(item => item !== value)
      setFieldValue("action_required_by", filteredData)
    }
  }

  const isChecked = value => {
    return actionRequireValue.includes(value)
  }
  return (
    <div>
      <div className="heading">Action required by:</div>
      <div className={"row1"}>
        <CheckBoxWithLabel
          label={"Product management"}
          value={isChecked('1')}
          onChange={e => handleOnChange('1', e.target.checked)}
          disabled={readOnly}
        />
        <CheckBoxWithLabel
          label={"Engineering"}
          value={isChecked('2')}
          onChange={e => handleOnChange('2', e.target.checked)}
          disabled={readOnly}
        />
        <CheckBoxWithLabel
          label={"Product support"}
          value={isChecked('3')}
          onChange={e => handleOnChange('3', e.target.checked)}
          disabled={readOnly}
        />
        <CheckBoxWithLabel
          label={"Publications"}
          value={isChecked('4')}
          onChange={e => handleOnChange('4', e.target.checked)}
          disabled={readOnly}
        />
        <CheckBoxWithLabel
          label={"Training"}
          value={isChecked('5')}
          onChange={e => handleOnChange('5', e.target.checked)}
          disabled={readOnly}
        />
        <CheckBoxWithLabel
          label={"Other"}
          value={isChecked('6')}
          onChange={e => handleOnChange('6', e.target.checked)}
          disabled={readOnly}
        />
      </div>
      {actionRequireValue.includes('6') && (
        <TextField
          placeholder={"Custom Text"}
          label={"Action Required"}
          name="action_required_text"
          type="text"
          styles={{ marginTop: 10 }}
          disabled={readOnly}
        />
      )}
    </div>
  )
}

export default ActionRequired
