export const errorHandler = (err) => {
    let error = '';
    for (const k in err.response?.data) {
        if (k === 'message') {
            error += err.response.data[k] + ', '
        } else {
            error += err.response.data[k][0] + ', '
        }
    }
    error = error.trim().slice(0, error.length - 2)
    return { type: 'failure', message : error}
}