import { BrowserRouter as Router } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import { getUtilityStateSelector } from './redux/selectors/utilitySelector';
import AppRoutes from './routes';
import './assets/styles/main.scss';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";

function App() {
  const utilityState = getUtilityStateSelector();
  const { showToast } = utilityState;

  useEffect(() => {
    if (showToast?.type === 'success') {
      toast.success(showToast?.message)
    } else if (showToast?.type === 'fail') {
      toast.error(showToast?.message)
    }
  }, [showToast])

  return (
    <>
      <Router>
        <ToastContainer />
        <AppRoutes />
      </Router>
    </>
  );
}

export default App;
