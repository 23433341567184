import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading:false,
    successMsg:'',
    errorMsg:'',
    showModal: false,
    ModalChildren: null,
    showToast:{
        isShow:false,
        type:'',
        message:''
    }
}

const utilitySlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.isLoading;
        },
        setSuccessMsg: (state, action) => {
            state.successMsg = action.message
        },
        setErrorsMsg: (state, action) => {
            state.errorMsg = action.message
        },
        setShowModal: (state, action) => {
            state.showModal = action.show
        },
        showToast: (state,action) => {
            const payload = action.payload
            state.showToast = {isShow:payload.show,message:payload.message,type:payload.type}
        }
        
    }
})

export default utilitySlice.reducer;
export const { setLoading, setSuccessMsg, setErrorMsg, setShowModal, showToast } = utilitySlice.actions;