import {call, put, take, takeLatest} from 'redux-saga/effects';
import {apiFunctions} from '../../api/functions';
import {errorHandler} from '../../utils/errorHandling';
import {
  // get risk assessment tasks
  getRiskAssessmentTasksFailure,
  getRiskAssessmentTasksSuccess,
  //fetTaskDetail,
  taskDetailSuccess,
  taskDetailFailure,
  // create task
  createTaskFailure,
  createTaskSuccess,
  // update Task
  updateTaskSuccess,
  updateTaskFailure,
  //  delete Task
  deleteTaskSuccess,
  deleteTaskFailure,
  // 'No' Exposure to events
  markNoExpToUnWantedEventsFailure,
  markNoExpToUnWantedEventsSuccess,
  // Add Event Subcategory
  addSubCategoryEventsFailure,
  addSubCategoryEventsSuccess,
  // Delete subCategory from event
  deleteSubCategoryEventsSuccess,
  deleteSubCategoryEventsFailure,
  saveEventDetailSuccess,
  saveEventDetailFailure,
  eventDetailSuccess,
  eventDetailFailure,
  updateEventDetailSuccess,
  updateEventDetailFailure,
  getMachineLimitsSuccess,
  getMachineLimitsFailure,
  addMachineLimitsSuccess,
  addMachineLimitsFailure,
  updateTaskMediaSuccess,
  updateTaskMediaFailure,
  uploadMediaSuccess,
  uploadMediaFailure,
  addAssumptionSuccess,
  addAssumptionFailure,
  getAssumptionSuccess,
  getAssumptionFailure,
  addRevisionHistorySuccess,
  addRevisionHistoryFailure,
  getRevisionHistorySuccess,
  getRevisionHistoryFailure,
  addAttendanceSuccess,
  getAttendanceSuccess,
  getAttendanceFailure,
  addAttendanceFailure,
  getEmployeeSuccess,
  getEmployeeFailure,
  getSummarySuccess,
  getSummaryFailure,
  //generate Excel Id
  generateExcelIdSuccess,
  generateExcelIdFailure,
  //generate Excel
  generateExcelSuccess,
  generateExcelFailure,
  //import Event
  importEventSuccess,
  importEventFailure,
} from './riskAssessmentCoreSlice';

import {limitsMock} from './mock';

// API Functions
const getRaTasksFromAPI = apiFunctions.getRiskAssessmentsTasks;
const createTaskFromAPI = apiFunctions.createTask;
const markNoEventsFromAPI = apiFunctions.markNoEvents;
const addEventSubCatFromAPI = apiFunctions.addEventSubCategory;
const deleteEventSubCatFromAPI = apiFunctions.deleteEventSubCategory;
const deleteTaskFromAPI = apiFunctions.deleteTask;
const updateTaskFromAPI = apiFunctions.updateTask;
const saveEventDetailFromAPI = apiFunctions.saveEventDetail;
const eventDetailFromAPI = apiFunctions.getEventDetail;
const updateEventDetailFromAPI = apiFunctions.updateEventDetail;
const getMachineLimitsFromAPI = apiFunctions.getMachineLimits;
const addMachineLimitsFromAPI = apiFunctions.addMachineLimits;
const uploadTaskMediaFromAPI = apiFunctions.updateTaskMedia;
const uploadMediaFromAPI = apiFunctions.uploadMedia;
const getAssumptionFromAPI = apiFunctions.getAssumption;
const addAssumptionFromAPI = apiFunctions.addAssumption;

const getRevisionHistoryFromAPI = apiFunctions.getRevisionHistory;
const addRevisionHistoryFromAPI = apiFunctions.addRevisionHistory;

const getAttendanceFromAPI = apiFunctions.getAttendance;
const addAttendanceFromAPI = apiFunctions.addAttendance;
const getEmployeeFromAPI = apiFunctions.getEmployee;
const getSummaryFromAPI = apiFunctions.getRASummary;

const generateExcelIdFromAPI = apiFunctions.generateExcelId;
const generateExcelFromAPI = apiFunctions.generateExcel;
const getTaskDetailFromAPI = apiFunctions.getTaskDetail;
const importEventFromAPI = apiFunctions.importEvent;

// get risk assessments Task
async function getRaTasks(payload) {
  return await getRaTasksFromAPI(payload);
}

function* getRaTasksHandler({payload}) {
  try {
    const result = yield call(getRaTasks, payload);
    yield put(getRiskAssessmentTasksSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getRiskAssessmentTasksFailure(err));
  }
}

// create Risk Assessment task
async function createTask(payload) {
  return await createTaskFromAPI(payload);
}

function* createTaskHandler({payload}) {
  try {
    const result = yield call(createTask, payload.payload);
    payload.callback(result);
    yield put(createTaskSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(createTaskFailure(err));
  }
}

// update Risk Assessment task
async function updateTask(id, payload) {
  return await updateTaskFromAPI(id, payload);
}

function* updateTaskHandler({payload}) {
  try {
    const result = yield call(updateTask, payload.id, payload.payload);
    payload.callback(result.data);
    yield put(updateTaskSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(updateTaskFailure(err));
  }
}
// delete Risk Assessment task
async function deleteTask(payload) {
  return await deleteTaskFromAPI(payload);
}

function* deleteTaskHandler({payload}) {
  try {
    const result = yield call(deleteTask, payload.id);
    payload.callback(result);
    yield put(deleteTaskSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(deleteTaskFailure(err));
  }
}

// update Risk Assessment task media
async function updateTaskMedia(id, payload) {
  return await uploadTaskMediaFromAPI(id, payload);
}

function* updateTaskMediaHandler({payload}) {
  try {
    const result = yield call(updateTaskMedia, payload.id, payload.payload);
    payload.callback(result.data);
    yield put(updateTaskMediaSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(updateTaskMediaFailure(err));
  }
}

// get risk assessments Task detail
async function getTaskDetail(id) {
  return await getTaskDetailFromAPI(id);
}

function* getTaskDetailHandler({payload}) {
  try {
    const result = yield call(getTaskDetail, payload.id);
    payload.callback(result.data);
    yield put(taskDetailSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(taskDetailFailure(err));
  }
}

export function* riskAssessmentTasksWatcher() {
  yield takeLatest(
    'riskAssessmentCore/getRiskAssessmentTasks',
    getRaTasksHandler,
  );
  yield takeLatest('riskAssessmentCore/createTask', createTaskHandler);
  yield takeLatest('riskAssessmentCore/deleteTask', deleteTaskHandler);
  yield takeLatest('riskAssessmentCore/updateTask', updateTaskHandler);
  yield takeLatest(
    'riskAssessmentCore/updateTaskMedia',
    updateTaskMediaHandler,
  );
  yield takeLatest('riskAssessmentCore/getTaskDetail', getTaskDetailHandler);
}

// Mark 'No' Exposure to unwanted Events
async function markNoEvents(id, payload) {
  return await markNoEventsFromAPI(id, payload);
}

function* noExpHandler({payload}) {
  try {
    const result = yield call(markNoEvents, payload.id, payload.payload);
    payload.callback(result);
    yield put(markNoExpToUnWantedEventsSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(markNoExpToUnWantedEventsFailure(err));
  }
}

// Add Event Subcategory
async function addEventSubCat(payload) {
  return await addEventSubCatFromAPI(payload);
}

function* addEventSubCategory({payload}) {
  try {
    const result = yield call(addEventSubCat, payload.payload);
    payload.callback(result);
    yield put(addSubCategoryEventsSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(addSubCategoryEventsFailure(err));
  }
}

// Delete an Event from Subcategory
async function deleteEventSubCat(id, payload) {
  return await deleteEventSubCatFromAPI(id, payload);
}

function* deleteEventSubCategory({payload}) {
  try {
    const result = yield call(deleteEventSubCat, payload.id, payload.payload);
    payload.callback(result);
    yield put(deleteSubCategoryEventsSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(deleteSubCategoryEventsFailure(err));
  }
}

async function saveEventDetail(payload) {
  return await saveEventDetailFromAPI(payload);
}

function* saveEventDetailHandler({payload}) {
  try {
    const result = yield call(saveEventDetail, payload.payload);
    payload.callback(result);
    yield put(saveEventDetailSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(saveEventDetailFailure(err));
  }
}

async function eventDetail(payload) {
  return await eventDetailFromAPI(payload);
}

function* eventDetailHandler({payload}) {
  try {
    const result = yield call(eventDetail, payload.payload);
    payload.callback(result.data);
    yield put(eventDetailSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(eventDetailFailure(err));
  }
}

async function updateEventDetail(id, payload) {
  return await updateEventDetailFromAPI(id, payload);
}

function* updateEventDetailHandler({payload}) {
  try {
    const result = yield call(updateEventDetail, payload.id, payload.payload);
    payload.callback(result);
    yield put(updateEventDetailSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(updateEventDetailFailure(err));
  }
}

// update Risk Assessment task media
async function uploadMedia(payload) {
  return await uploadMediaFromAPI(payload);
}

function* uploadMediaHandler({payload}) {
  try {
    const result = yield call(uploadMedia, payload.payload);
    payload.callback(result.data);
    yield put(uploadMediaSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(uploadMediaFailure(err));
  }
}

async function generateExcelId(payload) {
  return await generateExcelIdFromAPI(payload);
}

function* generateExcelIdHandler({payload}) {
  try {
    const result = yield call(generateExcelId, payload.payload);
    payload.callback(result.data);
    yield put(generateExcelIdSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(generateExcelIdFailure(err));
  }
}

async function generateExcel(id) {
  return await generateExcelFromAPI(id);
}

function* generateExcelHandler({payload}) {
  try {
    const result = yield call(generateExcel, payload.payload.id);
    payload.callback(result.data);
    yield put(generateExcelSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(generateExcelFailure(err));
  }
}

async function importEvent(payload) {
  return await importEventFromAPI(payload);
}

function* importEventHandler({payload}) {
  try {
    const result = yield call(importEvent, payload.payload);
    payload.callback(result.data);
    yield put(importEventSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(importEventFailure(err));
  }
}

export function* EventsWatcher() {
  yield takeLatest(
    'riskAssessmentCore/markNoExpToUnWantedEvents',
    noExpHandler,
  );
  yield takeLatest(
    'riskAssessmentCore/addSubCategoryEvents',
    addEventSubCategory,
  );
  yield takeLatest(
    'riskAssessmentCore/deleteSubCategoryEvents',
    deleteEventSubCategory,
  );
  yield takeLatest(
    'riskAssessmentCore/saveEventDetail',
    saveEventDetailHandler,
  );
  yield takeLatest('riskAssessmentCore/getEventDetail', eventDetailHandler);
  yield takeLatest(
    'riskAssessmentCore/updateEventDetail',
    updateEventDetailHandler,
  );
  yield takeLatest('riskAssessmentCore/uploadMedia', uploadMediaHandler);
  yield takeLatest(
    'riskAssessmentCore/generateExcelId',
    generateExcelIdHandler,
  );
  yield takeLatest('riskAssessmentCore/generateExcel', generateExcelHandler);
  yield takeLatest('riskAssessmentCore/importEvent', importEventHandler);
}

// ----------------------------- Nested data Risk Assessment -----------------------------------
// get Machine limits
async function getMachineLimits(id) {
  // return limitsMock
  return await getMachineLimitsFromAPI(id);
}

function* machineLimitsHandler({payload}) {
  try {
    const result = yield call(getMachineLimits, payload);
    // payload.callback(result)
    yield put(getMachineLimitsSuccess({raId: payload, data: result.data}));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getMachineLimitsFailure(err));
  }
}
// add Machine limits
async function addMachineLimits(id, payload) {
  return await addMachineLimitsFromAPI(id, payload);
}

function* addMachineLimitsHandler({payload}) {
  try {
    const result = yield call(addMachineLimits, payload.id, payload.payload);
    payload.callback(result);
    yield put(addMachineLimitsSuccess(result));
  } catch (error) {
    const err = errorHandler(error);
    yield put(addMachineLimitsFailure(err));
  }
}

// add Assumption
async function addAssumptions(id, payload) {
  return await addAssumptionFromAPI(id, payload);
}

function* addAssumptionsHandler({payload}) {
  try {
    const result = yield call(addAssumptions, payload.id, payload.payload);
    payload.callback(result);
    yield put(addAssumptionSuccess(result));
  } catch (error) {
    const err = errorHandler(error);
    yield put(addAssumptionFailure(err));
  }
}

// get Assumption
async function getAssumption(id) {
  return await getAssumptionFromAPI(id);
}

function* getAssumptionsHandler({payload}) {
  try {
    const result = yield call(getAssumption, payload);
    yield put(getAssumptionSuccess(result));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getAssumptionFailure(err));
  }
}

// add RevisionHistory
async function addRevisionHistory(id, payload) {
  return await addRevisionHistoryFromAPI(id, payload);
}

function* adRevisionHistoryHandler({payload}) {
  try {
    const result = yield call(addRevisionHistory, payload.id, payload.payload);
    payload.callback(result);
    yield put(addRevisionHistorySuccess(result));
  } catch (error) {
    const err = errorHandler(error);
    yield put(addRevisionHistoryFailure(err));
  }
}

// get revision history
async function getRevisionHistory(id) {
  return await getRevisionHistoryFromAPI(id);
}

function* getRevisionHistoryHandler({payload}) {
  try {
    const result = yield call(getRevisionHistory, payload);
    yield put(getRevisionHistorySuccess(result));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getRevisionHistoryFailure(err));
  }
}

// add attendance
async function addAttendance(payload) {
  return await addAttendanceFromAPI(payload);
}

function* addAttendanceHandler({payload}) {
  try {
    const result = yield call(addAttendance, payload.payload);
    payload.callback(result);
    yield put(addAttendanceSuccess(result));
  } catch (error) {
    const err = errorHandler(error);
    yield put(addAttendanceFailure(err));
  }
}

// get attendance
async function getAttendance(id) {
  return await getAttendanceFromAPI(id);
}

function* getAttendanceHandler({payload}) {
  try {
    const result = yield call(getAttendance, payload);
    yield put(getAttendanceSuccess(result));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getAttendanceFailure(err));
  }
}

// get Employee
async function getEmployee() {
  return await getEmployeeFromAPI();
}

function* getEmployeeHandler() {
  try {
    const result = yield call(getEmployee);
    yield put(getEmployeeSuccess(result));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getEmployeeFailure(err));
  }
}

// get Summary
async function getSummary(payload) {
  return await getSummaryFromAPI(payload);
}

function* getSummaryHandler({payload}) {
  try {
    const result = yield call(getSummary, payload);
    yield put(getSummarySuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(getSummaryFailure(err));
  }
}

export function* riskAssessmentNestedDataWatcher() {
  yield takeLatest('riskAssessmentCore/getMachineLimits', machineLimitsHandler);
  yield takeLatest(
    'riskAssessmentCore/addMachineLimits',
    addMachineLimitsHandler,
  );
  yield takeLatest('riskAssessmentCore/addAssumption', addAssumptionsHandler);
  yield takeLatest('riskAssessmentCore/getAssumptions', getAssumptionsHandler);

  yield takeLatest(
    'riskAssessmentCore/addRevisionHistory',
    adRevisionHistoryHandler,
  );
  yield takeLatest(
    'riskAssessmentCore/getRevisionHistory',
    getRevisionHistoryHandler,
  );
  yield takeLatest('riskAssessmentCore/addAttendance', addAttendanceHandler);
  yield takeLatest('riskAssessmentCore/getAttendance', getAttendanceHandler);
  yield takeLatest('riskAssessmentCore/getEmployee', getEmployeeHandler);
  yield takeLatest('riskAssessmentCore/getSummary', getSummaryHandler);
}
