import { call, put, take, takeLatest } from "redux-saga/effects"
import { apiFunctions } from "../../api/functions"
import {
    getTermsAndConditionsSuccess, getTermsAndConditionsFailure, getPrivacyPolicySuccess, getPrivacyPolicyFailure
} from "./termsConditionsSlice"
import { errorHandler } from "../../utils/errorHandling"

//API

const getTermsAndConditionsAPI = apiFunctions.getTermsAndConditions
const getPrivacyPolicyAPI = apiFunctions.getPrivacyPolicy

async function getTermsAndConditionsHandlerAPI() {
    return await getTermsAndConditionsAPI()
}

function* getTermsAndConditionsHandler() {
    try{
        const result = yield call(getTermsAndConditionsHandlerAPI)
        yield put(getTermsAndConditionsSuccess(result.data))
    }
    catch (error){
        const err = errorHandler(error)
        yield put(getTermsAndConditionsFailure(err))
    }
}

async function getPrivacyPolicyHandlerAPI() {
    return await getPrivacyPolicyAPI()
}

function* getPrivacyPolicyHandler() {
    try{
        const result = yield call(getPrivacyPolicyHandlerAPI)
        yield put(getPrivacyPolicySuccess(result.data))
    }
    catch (error){
        const err = errorHandler(error)
        yield put(getPrivacyPolicyFailure(err))
    }
}

export function* termsConditionsWatchers() {
    yield takeLatest("termsConditions/getTermsAndConditions", getTermsAndConditionsHandler)
    yield takeLatest("termsConditions/getPrivacyPolicy", getPrivacyPolicyHandler)
}