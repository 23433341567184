import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom';
import TaskInfo from './task-info';
import TaskEvents from './task-events/task-events';
import {useSelector} from 'react-redux';
import {
  getRiskAssessmentCoreLoadingState,
  getRiskAssessmentCoreTasksState,
  getRiskAssessmentTaskMapping,
  getTaskDetailState,
} from '../../../../../redux/selectors/riskAssessmentCore';
import {
  getRiskAssessmentTasks,
  getTaskDetail,
  updateTask,
  updateTaskMedia,
} from '../../../../../redux/risk-assessment-core/riskAssessmentCoreSlice';
import Loader from '../../../../../components/loader/loader';
import {useDispatch} from 'react-redux';
import './task-details.scss';
import Button from '../../../../../components/form/button/button';
import TaskMedia from './task-media/task-media';
import InternalNotes from './internal-notes/internal-notes';
import {getUserRoleName, taskStatusIDS} from '../../../../../utils/constants';
import { allRoutes } from '../../../../../utils/routeConstants';
import { getLoginStateSelector } from '../../../../../redux/selectors/loginSelector';

export default function TaskDetails() {
  const nav = useNavigate()

  const [finalEventData, setFinalEventData] = useState([]);
  const [showTaskMedia, setShowTaskMedia] = useState(false);
  const [showInternalNotes, setShowInternalNotes] = useState(false);
  const [internalNotes, setInternalNotes] = useState([]);

  const location = useLocation();
  const dispatch = useDispatch();
  const currentNavState = location.state;
  const selectedRiskAssessment = currentNavState.selectedRiskAssessment;
  const task_mapping = useSelector(getRiskAssessmentTaskMapping());
  const isLoading = useSelector(getRiskAssessmentCoreLoadingState());
  const taskList = useSelector(getRiskAssessmentCoreTasksState());
  const taskDetail =useSelector(getTaskDetailState());
  const loginState = useSelector(getLoginStateSelector());
  const {loggedInUser} = loginState;
  const readOnly = getUserRoleName(loggedInUser?.role) === 'general';

  const [taskMedia, setTaskMedia] = useState(
    currentNavState.selectedTask.media_details,
  );

  useEffect(() => {
    fetchTaskDetail(currentNavState.selectedTask.id);
  }, []);

  useEffect(() => {
    if (taskDetail?.id) {
      const notesData = taskDetail?.internal_note?.filter(
        item => item.internal_note.length > 0,
      );
      if (notesData.length > 0) {
        setInternalNotes(notesData);
      }
      setTaskMedia(taskDetail?.media_details);

      if (task_mapping?.size > 0) {
        let final = [];
        for (let [key, value] of task_mapping) {
          if (value.length === 1) {
            final.push(value[0]);
          } else {
            let parent = {...value.find(el => !el.event_sub_category)};
            let childs = [...value.filter(el => el.event_sub_category)];
            parent.exp_unwntd_events =
              childs[0]?.exp_unwntd_events || parent.exp_unwntd_events;
            parent['sub_cat_data'] = childs;
            final.push(parent);
          }
        }
        setFinalEventData([...final]);
      }
    }
  }, [taskDetail]);

  const fetchTaskDetail = id => {
    dispatch(
      getTaskDetail({
        id,
        callback: resp => {
          
        },
      }),
    );
  };

  const handleTaskCreation = (id, payload) => {
    dispatch(
      updateTask({
        id,
        payload,
        callback: resp => {
        //   nav(allRoutes.dashboardRoutes.assessmentRoutes.taskDetails, {
        //     state: {
        //         ...location.state,
        //         selectedTask: resp
        //     }
        // })
        },
      }),
    );
  };

  const handleMediaAdd = e => {
    const files = Array.from(e.target.files);
    // console.log('files', files)
    const payload = new FormData();
    files.forEach(file => {
      payload.append('media', file);
    });
    const id = currentNavState.selectedTask.id;
    dispatch(
      updateTaskMedia({
        id,
        payload,
        callback: resp => {
          setTaskMedia([...taskMedia, ...resp]);
        },
      }),
    );
  };

  return (
    <div className="task-details-wrapper">
      <header>
        <Row>
          <Col xs={7}>
            <div className="assessment-details">
              <div>
                <img
                  className="product-image"
                  src={selectedRiskAssessment?.product_detail?.image}
                />
              </div>
              <div style={{marginLeft: '0.75rem'}}>
                <div>{selectedRiskAssessment?.name}</div>
                <div>
                  Product line: {selectedRiskAssessment?.product_detail?.name}
                </div>
                <div>
                  {`Progress: ${
                    taskList?.filter(
                      item => item.task_status === taskStatusIDS.completed,
                    )?.length ?? 0
                  }/${taskList?.length ?? 0} task assessed`}
                  {/* {`Progress: ${selectedRiskAssessment?.task?.completed_task}/${selectedRiskAssessment?.task?.total_task} assessed`} */}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={5}>
            <div className="task-right-actions">
              <a onClick={() => setShowInternalNotes(internalNotes.length > 0)}>
                {`View Internal Notes(${internalNotes.length})`}
              </a>
              {taskMedia.length > 0 && (
                <a onClick={() => setShowTaskMedia(true)}>View Media</a>
              )}
              {!readOnly ? <div className="upload-media">
                <Button variant={'secondary-button'}>Upload Media</Button>
                <input multiple onChange={handleMediaAdd} type="file" />
              </div> : <></>}
            </div>
          </Col>
        </Row>
        <hr />
        <TaskInfo
          taskData={taskDetail}
          handleTaskCreation={handleTaskCreation}
        />
      </header>
      <article>
        <TaskEvents eventData={finalEventData} />
      </article>
      {showTaskMedia && (
        <TaskMedia
          medias={taskMedia}
          show={showTaskMedia}
          setShow={() => setShowTaskMedia(false)}
        />
      )}
      {showInternalNotes && (
        <InternalNotes
          show={showInternalNotes}
          setShow={() => setShowInternalNotes(false)}
          label="Internal Notes"
          notes={internalNotes}
        />
      )}
      {isLoading && <Loader />}
    </div>
  );
}
