import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { assets } from '../../utils/assetsSrc';
import Button from '../../components/form/button/button';
import './fileUpload.scss';

export default function FileUpload({ handleFileUpload ,productImg}) {
    const [uploadStatus, setUploadStatus] = useState('Drag & drop file you want to upload');
    const [file, setFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(productImg);
    const onDrop = useCallback(acceptedFile => {
        // Do something with the files
        setFile(acceptedFile[0])

        // setUploadStatus('Uploading')
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    useEffect(() => {
        // Handle file upload
        if (file) {
            setPreviewImage(URL.createObjectURL(file))
            handleFileUpload(file)
        }
    }, [file])

    const handleImageChange = (event) => {
        setFile(event.target.files[0]);
    }
    return (
        <>
            <div {...getRootProps()} className='upload-progress-bar'>
                {previewImage ? <img style={{height:'120px'}} src={previewImage} /> : <>
                    <img className={uploadStatus === 'uploading' ? 'upload-image position-image' : 'upload-image'} src={assets.uploadIcon} />
                    <span className={uploadStatus === 'uploading' ? 'position-status' : ''}>{uploadStatus}</span>
                </>}
            </div>
            <div className='browse'>
                <Button variant='submit-button'>
                    <label className="custom-file-upload">
                        <input {...getInputProps()} type="file" onChange={handleImageChange} />
                        Browse
                    </label>
                </Button>
            </div>
        </>
    )
}
