import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {assets} from '../../../../../utils/assetsSrc';
import {ErrorMessage, Form, Formik, useFormikContext} from 'formik';
import * as Yup from 'yup';
import TextField from '../../../../../components/form/textfield/textfield';
import Button from '../../../../../components/form/button/button';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import MultiSelectDropdown from '../../../../../components/form/multiSelectDropdown/MultiSelectDropdown';
import {useSelector} from 'react-redux';
import {getUserListSelector} from '../../../../../redux/selectors/userList';
import SingleSelectDropdown from '../../../../../components/form/singleSelectDropdown/singleSelectDropdown';
import {getproductLineStateSelector} from '../../../../../redux/selectors/riskAssessment';
import {
  riskAssessmentAccessIds,
  riskAssessmentAccessTypes,
} from '../../../../../utils/constants';
import {useDispatch} from 'react-redux';
import {apiFunctions} from '../../../../../api/functions';
import Loader from '../../../../../components/loader/loader';
import './newRiskAsessment.scss';
import {useLocation} from 'react-router-dom';

const SideEffectHandler = ({handleProductLineChange}) => {
  const {values, submitForm, setFieldValue} = useFormikContext();
  const [isLoadingFistTime, setIsLoadingFirstTime] = useState(true);

  useEffect(() => {
    if (values.productLine?.id ) {
      if(!isLoadingFistTime)
      setFieldValue('modelsList', []);
      handleProductLineChange(values.productLine?.id);
    }
    setIsLoadingFirstTime(false);
  }, [values.productLine]);
};

export default function NewRiskAsessment({
  label,
  show,
  setShow,
  prefilledData,
  action,
  handleRiskAssessmentAction,
}) {
  const [userList, setUserList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [raAccess, setRaAccess] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [myFormik, setMyFormik] = useState({});
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const userListState = useSelector(getUserListSelector());
  const productLineData = useSelector(getproductLineStateSelector());
  const location = useLocation();
  const accessOptionsLabel = {
    restricted: 'Restricted',
    normal: 'Normal',
    unrestricted: 'Unrestricted',
  };
  const initialValues = prefilledData?.data
    ? prefilledData.data
    : {
        riskAssessmentName: '',
        accessType: 'normal',
        userAccessList: [],
        productLine: location.state?.selectedProductLine,
        modelsList: location.state?.selectedModel
          ? [location.state?.selectedModel]
          : [],
      };
  const validate = Yup.object({
    riskAssessmentName: Yup.string().required('Name is Required'),
    productLine: Yup.object().required('Product Line is Required'),
    modelsList: Yup.array().min(1, 'Model is Required'),
  });

  useEffect(() => {
    setUserList(userListState.userList || []);
  }, [userListState]);
  useEffect(() => {
    if (prefilledData?.access) {
      const access = prefilledData.access === 1 ? true : false;
      setRaAccess(access);
    }
  }, []);

  // const handleSelectedUsersChange = () => {

  // }

  const handleProductLineChange = productLineId => {
    setIsLoading(true);
    apiFunctions
      .getProductLineModels({productLineId, config: {}})
      .then(resp => {
        setModelsList(resp.data[0]?.machine);
      })
      .catch(err => console.log('error occured', err))
      .finally(() => setIsLoading(false));
  };
  const handleSubmit = vals => {
    const payload = {
      name: vals.riskAssessmentName,
      access: riskAssessmentAccessIds[vals.accessType],
      users: vals.userAccessList.map(el => el.id),
      product: vals.productLine.id,
      //assessment_type: raAccess ? 1 : 2 ,
      machine: vals.modelsList.map(el => el.id),
    };
    handleRiskAssessmentAction(payload);
    // dispatch(createRiskAssessment({
    //     payload, callback: (response) => {

    //     }
    // }))
  };
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="new-risk-assessment"
      centered
      className="new-risk-assessment">
      <Modal.Body className="p-5 px-5 text-center">
        <div className="header">
          <p className="label mt-3 mb-4">{label}</p>
          <img onClick={() => setShow(false)} src={assets.closeIcon} />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validate}
          onSubmit={handleSubmit}>
          {({formik, values, setFieldValue, isSubmitting, isValid, errors}) => (
            <Form>
              <div
                className="risk-assess-row"
                style={{display: 'flex', justifyContent: 'space-between'}}>
                <TextField
                  name="riskAssessmentName"
                  type="text"
                  label="Risk assessment name"
                  placeholder="Name of risk assessment"
                />
                {/* <span className='ra-access' onClick={() => setRaAccess(!raAccess)}>
                                    {raAccess ? 'private' : 'public'}
                                    <img src={assets.raAccessPrivate} />
                                </span> */}
              </div>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Access</FormLabel>
                  <RadioGroup
                    name="accessType"
                    value={values.accessType}
                    onChange={event => {
                      setFieldValue('accessType', event.currentTarget.value);
                    }}>
                    <FormControlLabel
                      value={riskAssessmentAccessTypes.RESTRICTED}
                      control={<Radio />}
                      label={accessOptionsLabel.restricted}
                    />
                    <FormControlLabel
                      value={riskAssessmentAccessTypes.NORMAL}
                      control={<Radio />}
                      label={accessOptionsLabel.normal}
                    />
                    {/* <FormControlLabel value={riskAssessmentAccessTypes.UNRESTRICTED} control={<Radio />} label={accessOptionsLabel.unrestricted} /> */}
                  </RadioGroup>
                </FormControl>
              </div>
              {/* {(values.accessType === riskAssessmentAccessTypes.RESTRICTED) &&
                                <div className='user-multiselect'>
                                    <MultiSelectDropdown label={'User access'} listItems={userList} name='userAccessList'
                                        value={values['userAccessList']}
                                        renderChipsInside={false} handleSelectedUsersChange={(val) => setFieldValue('userAccessList', val)} />
                                </div>} */}
              <div className="user-multiselect">
                <SingleSelectDropdown
                  label={'Product'}
                  listItems={productLineData}
                  name="productLine"
                  value={values['productLine']}
                  handleSelectedUsersChange={val => {
                    setFieldValue('productLine', val);
                  }}
                  showError={true}
                />
              </div>

              <div className="user-multiselect">
                <MultiSelectDropdown
                  label={'Models'}
                  listItems={modelsList}
                  name="modelsList"
                  value={values['modelsList']}
                  renderChipsInside={true}
                  handleSelectedUsersChange={val =>
                    setFieldValue('modelsList', val)
                  }
                  showError={true}
                />
              </div>
              <div className="action-buttons">
                <Button
                  type="button"
                  clickHandler={() => setShow(false)}
                  variant="secondary-button">
                  Cancel
                </Button>
                <Button type="submit" variant="submit-button">
                  {action === 'edit' ? 'Update' : 'Create'}
                </Button>
              </div>
              <SideEffectHandler
                handleProductLineChange={handleProductLineChange}
              />
            </Form>
          )}
        </Formik>
        {isLoading && <Loader />}
      </Modal.Body>
    </Modal>
  );
}
