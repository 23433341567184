import { createSelector } from "@reduxjs/toolkit";

const feedbackListState = (state) => {
    return state.persistedReducer.feedbackReducer
}

export const getFeedbackListSelector = () => createSelector(feedbackListState,(state) =>{
    return state?.feedbackList || []
})

export const getFeedbackLoadingState = () => createSelector(feedbackListState,(state) =>{
    return state?.isLoading || false
})