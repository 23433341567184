import React from 'react';
import { assets } from '../../utils/assetsSrc';

import './splash-wrapper.scss';

export default function SplashWrapper({gradient, children}) {
  return (<>
    <div className='splash-screen' style={{backgroundImage:`url(${assets.komatsuSplash})`}}>
      {children}
    </div>
        <div className={`layer ${gradient==100?'gradient100':''}`}></div>
        </>
  )
}
