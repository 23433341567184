import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { getUserRoleName, lifeCyclePhasesEnum, taskFrequencyEnum, taskStatusEnum, taskStatusIDS } from '../../../../../utils/constants'
import { assets } from '../../../../../utils/assetsSrc'
import './task-card.scss'
import Confirmation from '../../../../../components/confirmation/confirmation'
import { useSelector } from 'react-redux'
import { getLoginStateSelector } from '../../../../../redux/selectors/loginSelector'

export default function TaskCard({ data, handleTaskSelection, handleTaskDelete, handleTaskDuplicate }) {
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const loginState = useSelector(getLoginStateSelector());
  const {loggedInUser} = loginState;
  const readOnly = getUserRoleName(loggedInUser?.role) === 'general';

  return (
    <>
      <div onClick={handleTaskSelection} className='task-card-wrapper'>
        <Row>
          <Col xs={9}>
            <span className='task-name'>{data?.name}</span>
          </Col>
          <Col className="task-status-wrapper" xs={3} style={{ textAlign: 'right' }}>
            {/* <span className='access'><img src={data.access === 1 ? assets.lockFilled : assets.lock} /></span> */}
            <span
              className={`${
                data?.task_status === taskStatusIDS.completed
                  ? "task-completed"
                  : "task-progress"
              } task-status`}
            >
              {taskStatusEnum.find(item => item.id === data?.task_status)?.name  }
            </span>
          </Col>
        </Row>
        <Row>
          <span className='task-details'>{data.details}</span>
        </Row>
        <Row>
          <Col xs={4}>
            <div className='sub-heading'>Life cycle phases</div>
            <div>{lifeCyclePhasesEnum.find(phase => phase.id === data?.life_cycle_phase)?.name}</div>
          </Col>
          <Col xs={4} style={{ textAlign: 'center' }}>
            <div className='sub-heading'>Task subcategory</div>
            {/* <div>{data?.sub_category_name}</div> */}
            <div>{data?.task_sub_categories||'-'}</div>
          </Col>
          <Col xs={4} style={{ textAlign: 'right' }}>
            <div className='sub-heading'>Task frequency</div>
            <div>{taskFrequencyEnum.find(frequency => frequency.id === data?.frequency)?.name}</div>
          </Col>
        </Row>
        { !readOnly && (
        <Row style={{ marginTop: '7px' }}>
          <Col xs={6}>
            <span onClick={(e) => { handleTaskDuplicate(); e.stopPropagation() }}>
              <img src={assets.copyIcon} />
              <span>Duplicate</span>
            </span>
          </Col>
          <Col xs={6} style={{ textAlign: 'right' }}>
            <span onClick={(e) => { setDeleteConfirm(true); e.stopPropagation() }}><img src={assets.trash} />
              <span>Delete</span>
            </span>
          </Col>
        </Row>
        )}
      </div>
      <Confirmation
        label={'Delete Task'}
        show={deleteConfirm}
        setShow={setDeleteConfirm}
        confirmationType='typeDelete'
        secondaryButtonClickHandler={() => setDeleteConfirm(false)}
        primaryButtonClickHandler={() => { handleTaskDelete(); setDeleteConfirm(false) }}
      />
    </>
  )
}
