import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {LimitTypesIds} from '../../utils/constants';

const initialState = {
  isLoading: false,
  riskAssessmentTasks: [],
  task_event_mapping: [],
  machineLimits: {},
  assumptions: {},
  revisionHistory: [],
  attendance: [],
  employee: [],
  summary: [],
  task_detail: {},
};

const riskAssessmentCoreSlice = createSlice({
  name: 'riskAssessmentCore',
  initialState,
  reducers: {
    // get Risk Assessments Tasks
    getRiskAssessmentTasks: state => {
      state.isLoading = true;
    },
    getRiskAssessmentTasksSuccess: (state, action) => {
      const response = action.payload;
      state.riskAssessmentTasks = response;
      state.isLoading = false;
    },
    getRiskAssessmentTasksFailure: state => {
      state.isLoading = false;
      state.riskAssessmentTasks = [];
    },

    // get Risk Assessments Task detail
    getTaskDetail: (state) => {
      state.isLoading = true;
      
    },
    taskDetailSuccess: (state, action) => {
      state.isLoading = false;
      const taskDetail = action.payload;
      const myMap = new Map();
      taskDetail.task_event_mapping.forEach(el => {
        if (!myMap.get(el.event)) {
          myMap.set(el.event, [el]);
        } else {
          myMap.set(el.event, [...myMap.get(el.event), el]);
        }
      });
      state.task_event_mapping = myMap;
      state.task_detail = taskDetail;
    },
    taskDetailFailure: state => {
      state.isLoading = false;
    },
    // create Risk Assessments Task
    createTask: state => {
      state.isLoading = true;
    },
    createTaskSuccess: state => {
      state.isLoading = false;
    },
    createTaskFailure: state => {
      state.isLoading = false;
    },

    // update Risk Assessments Task
    updateTask: state => {
      state.isLoading = true;
    },
    updateTaskSuccess: (state,action) => {
      state.isLoading = false;
      const taskDetail = action.payload;
      const myMap = new Map();
      taskDetail.task_event_mapping.forEach(el => {
        if (!myMap.get(el.event)) {
          myMap.set(el.event, [el]);
        } else {
          myMap.set(el.event, [...myMap.get(el.event), el]);
        }
      });
      state.task_event_mapping = myMap;
      state.task_detail = taskDetail;
    },
    updateTaskFailure: state => {
      state.isLoading = false;
    },
    // update Risk Assessments Task Media
    updateTaskMedia: state => {
      state.isLoading = true;
    },
    updateTaskMediaSuccess: state => {
      state.isLoading = false;
      toast.success('Media Uploaded Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    updateTaskMediaFailure: state => {
      state.isLoading = false;
    },
    // Delete a Risk Assessments Task
    deleteTask: state => {
      state.isLoading = true;
    },
    deleteTaskSuccess: state => {
      state.isLoading = false;
      toast.success('Task Deleted Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    deleteTaskFailure: (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      toast.error(response.message, {
        autoClose: 2000,
        className: 'toast-fail',
      });
    },

    // Mark no to event
    markNoExpToUnWantedEvents: state => {
      state.isLoading = true;
    },
    markNoExpToUnWantedEventsSuccess: state => {
      state.isLoading = false;
    },
    markNoExpToUnWantedEventsFailure: state => {
      state.isLoading = false;
    },

    // Add sub category to events
    addSubCategoryEvents: state => {
      state.isLoading = true;
    },
    addSubCategoryEventsSuccess: state => {
      state.isLoading = false;
    },
    addSubCategoryEventsFailure: state => {
      state.isLoading = false;
    },

    // Delete a sub category from event
    deleteSubCategoryEvents: state => {
      state.isLoading = true;
    },
    deleteSubCategoryEventsSuccess: state => {
      state.isLoading = false;
    },
    deleteSubCategoryEventsFailure: state => {
      state.isLoading = false;
    },

    // get machine limits data
    getMachineLimits: state => {
      state.isLoading = true;
    },
    getMachineLimitsSuccess: (state, action) => {
      const updated = {...state.machineLimits};
      updated[action.payload.raId] = action.payload.data;
      const obj = {
        space: [],
        time: [],
        use: [],
        other: [],
      };
      action.payload.data.forEach(el => {
        if (el.type === LimitTypesIds['SPACE']) {
          obj.space.push(el);
        } else if (el.type === LimitTypesIds['TIME']) {
          obj.time.push(el);
        } else if (el.type === LimitTypesIds['USE']) {
          obj.use.push(el);
        } else if (el.type === LimitTypesIds['OTHER']) {
          obj.other.push(el);
        }
      });
      state.machineLimits = obj;
      state.isLoading = false;
    },
    getMachineLimitsFailure: state => {
      state.isLoading = false;
    },

    // add machine limits data
    addMachineLimits: state => {
      state.isLoading = true;
    },
    addMachineLimitsSuccess: (state, action) => {
      state.isLoading = false;
    },
    addMachineLimitsFailure: state => {
      state.isLoading = false;
    },
    //save event detail
    saveEventDetail: state => {
      state.isLoading = true;
    },
    saveEventDetailSuccess: state => {
      state.isLoading = false;
    },
    saveEventDetailFailure: state => {
      state.isLoading = false;
    },

    //
    getEventDetail: state => {
      state.isLoading = true;
    },
    eventDetailSuccess: (state, action) => {
      // const response = action.payload
      // state.eventDetail = response
      state.isLoading = false;
    },
    eventDetailFailure: state => {
      state.isLoading = false;
    },

    //update event detail
    updateEventDetail: state => {
      state.isLoading = true;
    },
    updateEventDetailSuccess: state => {
      state.isLoading = false;
    },
    updateEventDetailFailure: state => {
      state.isLoading = false;
    },

    //upload Media
    uploadMedia: state => {
      state.isLoading = true;
    },
    uploadMediaSuccess: state => {
      state.isLoading = false;
      toast.success('Media Uploaded Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    uploadMediaFailure: state => {
      state.isLoading = false;
    },

    // get Assumption
    getAssumptions: state => {
      state.isLoading = true;
    },
    getAssumptionSuccess: (state, action) => {
      state.isLoading = false;
      state.assumptions = action.payload.data;
    },
    getAssumptionFailure: state => {
      state.isLoading = false;
    },

    // add Assumption
    addAssumption: state => {
      state.isLoading = true;
    },
    addAssumptionSuccess: (state, action) => {
      state.isLoading = false;
    },
    addAssumptionFailure: state => {
      state.isLoading = false;
    },

    // get RevisionHistory
    getRevisionHistory: state => {
      state.isLoading = true;
    },
    getRevisionHistorySuccess: (state, action) => {
      state.isLoading = false;
      state.revisionHistory = action.payload.data;
    },
    getRevisionHistoryFailure: state => {
      state.isLoading = false;
    },

    // add RevisionHistory
    addRevisionHistory: state => {
      state.isLoading = true;
    },
    addRevisionHistorySuccess: (state, action) => {
      state.isLoading = false;
    },
    addRevisionHistoryFailure: state => {
      state.isLoading = false;
    },

    // get attendance
    getAttendance: state => {
      state.isLoading = true;
    },
    getAttendanceSuccess: (state, action) => {
      state.isLoading = false;
      state.attendance = action.payload.data;
    },
    getAssumptionFailure: state => {
      state.isLoading = false;
    },

    // add attendance
    addAttendance: state => {
      state.isLoading = true;
    },
    addAttendanceSuccess: (state, action) => {
      state.isLoading = false;
      toast.success('Attendance added Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    addAttendanceFailure: state => {
      state.isLoading = false;
    },

    // get Employee
    getEmployee: state => {
      state.isLoading = true;
    },
    getEmployeeSuccess: (state, action) => {
      state.isLoading = false;
      state.employee = action.payload.data;
    },
    getEmployeeFailure: state => {
      state.isLoading = false;
    },

    // get Summary
    getSummary: state => {
      state.isLoading = true;
    },
    getSummarySuccess: (state, action) => {
      state.isLoading = false;
      state.summary = action.payload;
    },
    getSummaryFailure: state => {
      state.isLoading = false;
    },
    //generate Excel Id
    generateExcelId: state => {
      // state.isLoading = true
    },
    generateExcelIdSuccess: (state, action) => {
      state.isLoading = false;
      // state.excelStatus = action.payload
    },
    generateExcelIdFailure: state => {
      state.isLoading = false;
    },

    //generate Excel
    generateExcel: state => {
      // state.isLoading = true
    },

    generateExcelSuccess: (state, action) => {
      state.isLoading = false;
      // state.excelDetails = action.payload
    },

    generateExcelFailure: (state, action) => {
      state.isLoading = false;
    },

    //import-event
    importEvent: state => {
      state.isLoading = true;
    },

    importEventSuccess: state => {
      state.isLoading = false;
      toast.success('Imported Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },

    importEventFailure: (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      toast.error(response.message, {
        autoClose: 2000,
        className: 'toast-fail',
      });
    },
  },
});

export default riskAssessmentCoreSlice.reducer;
export const {
  // get product lines
  getRiskAssessmentTasks,
  getRiskAssessmentTasksSuccess,
  getRiskAssessmentTasksFailure,
  //task detail,
  getTaskDetail,
  taskDetailSuccess,
  taskDetailFailure,
  // create task
  createTask,
  createTaskSuccess,
  createTaskFailure,
  // update task
  updateTask,
  updateTaskSuccess,
  updateTaskFailure,
  // delete task
  deleteTask,
  deleteTaskSuccess,
  deleteTaskFailure,
  // update  task media
  updateTaskMedia,
  updateTaskMediaSuccess,
  updateTaskMediaFailure,
  // 'No' exposure to events
  markNoExpToUnWantedEvents,
  markNoExpToUnWantedEventsSuccess,
  markNoExpToUnWantedEventsFailure,
  // Add subcategory to an event
  addSubCategoryEvents,
  addSubCategoryEventsSuccess,
  addSubCategoryEventsFailure,
  // Delete subcategory from an event
  deleteSubCategoryEvents,
  deleteSubCategoryEventsSuccess,
  deleteSubCategoryEventsFailure,
  //  Get Machine limits Data
  getMachineLimits,
  getMachineLimitsSuccess,
  getMachineLimitsFailure,
  //  Add Machine limits Data
  addMachineLimits,
  addMachineLimitsSuccess,
  addMachineLimitsFailure,

  // save event detail
  saveEventDetail,
  saveEventDetailSuccess,
  saveEventDetailFailure,
  // event detail
  getEventDetail,
  eventDetailSuccess,
  eventDetailFailure,
  // update event detail
  updateEventDetail,
  updateEventDetailSuccess,
  updateEventDetailFailure,
  // upload media
  uploadMedia,
  uploadMediaSuccess,
  uploadMediaFailure,

  //  Get assumptions Data
  getAssumptions,
  getAssumptionSuccess,
  getAssumptionFailure,
  //  Add assumption Data
  addAssumption,
  addAssumptionSuccess,
  addAssumptionFailure,

  //  Get Revision History
  getRevisionHistory,
  getRevisionHistorySuccess,
  getRevisionHistoryFailure,
  //  Add RevisionHistory
  addRevisionHistory,
  addRevisionHistorySuccess,
  addRevisionHistoryFailure,

  //  Get Attendance
  getAttendance,
  getAttendanceSuccess,
  getAttendanceFailure,
  //  Add Attendance
  addAttendance,
  addAttendanceSuccess,
  addAttendanceFailure,
  //  Get Employee
  getEmployee,
  getEmployeeSuccess,
  getEmployeeFailure,
  //Summary
  getSummary,
  getSummarySuccess,
  getSummaryFailure,

  //generate Excel Id
  generateExcelId,
  generateExcelIdSuccess,
  generateExcelIdFailure,

  //generate Excel
  generateExcel,
  generateExcelSuccess,
  generateExcelFailure,

  //import Event
  importEvent,
  importEventSuccess,
  importEventFailure,
} = riskAssessmentCoreSlice.actions;
