import {Form, Formik} from 'formik';
import {Modal} from 'react-bootstrap';
import Button from '../../../components/form/button/button';
import SingleSelectDropdown from '../../../components/form/singleSelectDropdown/singleSelectDropdown';
import TextField from '../../../components/form/textfield/textfield';
import { assets } from '../../../utils/assetsSrc';
import * as Yup from "yup"
import {getUserRoleName, roleTypesEnum} from '../../../utils/constants';
import './user-list.scss';

export default function EditUser({
    label,
    show,
    setShow,
    prefilledData,
    onClickConfirm
}) {

  const initialValues = {
    name: prefilledData.fullname,
    emailId: prefilledData.email,
    jobTitle: prefilledData.job_title,
    role: roleTypesEnum[prefilledData.role - 1],
  };

  const validate = Yup.object({
      role: Yup.object().required("Role is Required")
  })

  const handleSubmit = (vals) => {
      const payload ={    
        role: vals.role.id
      }
      onClickConfirm(payload)
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="confirm-cancel"
      centered
      className="edit-user">
      <Modal.Body className="p-5 px-5 text-center">
        <div className="header">
          <p className="label mt-3 mb-4">{label}</p>
          <img onClick={() => setShow(false)} src={assets.closeIcon} />
        </div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validate}>
          {({formik, isSubmitting, isValid, errors, setFieldValue, values, touched}) => (
            <Form>
              <div className="user-form">
                <TextField type="text" label="Name" name="name" disabled/>
                <TextField type="text" label="Email Id" name="emailId" disabled/>
                <TextField type="text" label="Job title" name="jobTitle" disabled/>
              </div>
              <div className="user-multiselect">
                <SingleSelectDropdown
                  label="Role type"
                  name="role"
                  listItems={roleTypesEnum}
                  value={values["role"]}
                  handleSelectedUsersChange={val => {
                    setFieldValue("role", val);
                  }}
                  showError={true}
                />

                <div className="action-buttons">
                  <Button
                    type="button"
                    clickHandler={() => setShow(false)}
                    variant="secondary-button">
                    Cancel
                  </Button>
                  <Button type="submit" variant="submit-button">
                    Update
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
