import React from 'react';
import './assumptions.scss';
import Button from '../../../../../components/form/button/button';
import TextArea from '../../../../../components/form/textArea/TextArea';

const AssumptionItem = ({label, value, handleSave, id}) => {
  return (
    <div className="item-wrapper">
      <div className="action-button-wrapper">
        <div className="label">{label}</div>
        <Button
          type="button"
          variant="secondary-button"
          clickHandler={() => {
            handleSave(id, value, label);
          }}>
          {value ? 'Update' : 'Add'}
        </Button>
      </div>
      <div className="assumption-text">
        <div>{value}</div>
      </div>
    </div>
  );
};

export default AssumptionItem;
