import { Form, Formik } from "formik"
import { Modal } from "react-bootstrap"
import { assets } from "../../../../../utils/assetsSrc"
import { lifeCyclePhasesEnum, riskAssessmentStatusId, riskAssessmentStatusNames, riskAssessmentStatusTypes, taskFrequencyEnum } from "../../../../../utils/constants"
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
  } from "@mui/material"
import SingleSelectDropdown from "../../../../../components/form/singleSelectDropdown/singleSelectDropdown"
import Button from "../../../../../components/form/button/button"
import "./filter.scss"


export default function Filter({label, show, setShow, handleFilterChange,config}){

      const initialValues = {
          lifeCyclePhases: lifeCyclePhasesEnum.find(item=> item.id===config.life_cycle_phase)??'',
          taskFrequency: taskFrequencyEnum.find(item=> item.id===config.frequency)??'',
          statusType: riskAssessmentStatusNames[config.status]??''
      }

    //   const validate = Yup.object({
    //     lifeCyclePhasesEnum: Yup.object().required('Life cycle phases is required')
    //   })

      const handleSubmit = (vals) => {
        const payload = {
            life_cycle_phase: vals.lifeCyclePhases.id,
            frequency: vals.taskFrequency.id,
            status: riskAssessmentStatusId[vals.statusType]
        }
        handleFilterChange(payload)
      }

      return (
          <Modal
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="filter"
            centered
            className="filter"
          >
              <Modal.Body className="p-5 px-5 text-center">
                <div className="header">
                    <p className="label mt-3 mb-4">{label}</p>
                    <img onClick={() => setShow(false)} src={assets.closeIcon} />
                </div>
                <Formik 
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({formik, values, setFieldValue, isSubmitting, isValid, errors}) => (
                        <Form>
                            <div className="user-multiselect">
                                <SingleSelectDropdown
                                    label={'Life cycle phases'}
                                    listItems={lifeCyclePhasesEnum}
                                    name="lifeCyclePhases"
                                    value={values['lifeCyclePhases']}
                                    handleSelectedUsersChange={val => {
                                        setFieldValue('lifeCyclePhases', val);
                                    }}
                                />
                            </div>
                            <div className="user-multiselect">
                                <SingleSelectDropdown
                                    label={'Task frequency'}
                                    listItems={taskFrequencyEnum}
                                    name="taskFrequency"
                                    value={values['taskFrequency']}
                                    handleSelectedUsersChange={val => {
                                        setFieldValue('taskFrequency', val);
                                    }}
                                />
                            </div>
                            <div>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Status</FormLabel>
                                    <RadioGroup
                                        name="accessType"
                                        value={values.statusType}
                                        onChange={event => {
                                        setFieldValue("statusType", event.currentTarget.value)
                                        }}
                                    >
                                    <FormControlLabel
                                        value={riskAssessmentStatusTypes.NOT_STARTED}
                                        control={<Radio />}
                                        label={"Not Started"}
                                    />
                                    <FormControlLabel
                                        value={riskAssessmentStatusTypes.IN_PROGRESS}
                                        control={<Radio />}
                                        label={"In Progress"}
                                    />
                                    <FormControlLabel
                                        value={riskAssessmentStatusTypes.COMPLETED}
                                        control={<Radio />}
                                        label={"Completed"}
                                    />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className="action-buttons">
                                <Button
                                    type="submit"
                                    clickHandler={() => {
                                        setFieldValue('lifeCyclePhases', "");
                                        setFieldValue('taskFrequency', "");
                                        setFieldValue('statusType', "");
                                    }}
                                    variant="secondary-button">
                                    Reset
                                </Button>
                                {/* <Button
                                    type="button"
                                    clickHandler={() => setShow(false)}
                                    variant="secondary-button"
                                >
                                    Cancel
                                </Button> */}
                                <Button type="submit" variant="submit-button">
                                    Apply
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
              </Modal.Body>

          </Modal>
      )
}