import React, { useEffect, useState } from 'react';
// mocks
import { machineListData } from '../../../mocks/machineList';

import RiskAccessCard from '../../../components/risk-assess-card/risk-access.card';
import MachineCard from './components/machine-card/machineCard';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { crumbHref, crumbLabel, currentPath } from './utils/breadcrumb';
import Loader from '../../../components/loader/loader';
import { useSelector } from 'react-redux';
import { getRiskAssessmentLoadingState } from '../../../redux/selectors/riskAssessment';
import { assets } from '../../../utils/assetsSrc';
import './assessment.scss';

export default function Assessment() {
    const [navList, setNavList] = useState([]);
    const location = useLocation();
    const nav = useNavigate()
    const isLoading = useSelector(getRiskAssessmentLoadingState())
    const dynamicCrumbs = ['risk-assessments', 'risk-assessment', 'task-detail','event-detail']

    useEffect(() => {
        // console.log('location-->>', location)
        const pathName = location.pathname
        const paths = pathName.split('/').filter(path => path !== '').slice(2,)
        // console.log('all paths', paths)
        if (paths[0] === 'product-line') {
            setNavList([]);
            return
        }
        if (!navList.find(el => el.name === 'product-line')?.name) {
            paths.unshift('product-line')
        }
        createBreadCrumbNav(paths)
    }, [location])

    const manageDynamicCrumbData = (path) => {
        let element;
        switch (path) {
            case 'risk-assessments':
                element = {
                    name: 'risk-assessments',
                    label: location.state.selectedRiskAssessment === 'all' ? `Assessments(${location.state.raCount??'all'})` : location.state.selectedRiskAssessment,
                    onClick: (val = true) => {
                        if (val) {
                            const index = navList.findIndex(el => el.name === path)
                            setNavList(navList.slice(index + 1))
                            nav(crumbHref[path], { state: { ...location.state } })
                        }
                    }
                }
                break
            case 'risk-assessment':
                element = {
                    name: 'risk-assessment',
                    label: location.state.selectedRiskAssessment?.name || '--',
                    onClick: (val = true) => {
                        if (val) {
                            const index = navList.findIndex(el => el.name === path)
                            setNavList(navList.slice(index + 1))
                            nav(crumbHref[path], { state: { ...location.state } })
                        }
                    }
                }
                break
            case 'task-detail':
                element = {
                    name: 'task-detail',
                    label: location.state.selectedTask?.name || '--',
                    onClick: (val = true) => {
                        if (val) {
                            const index = navList.findIndex(el => el.name === path)
                            setNavList(navList.slice(index + 1))
                           //setNavList(navList.slice(0))
                            nav(crumbHref[path], { state: { ...location.state } })
                        }
                    }
                }
                break;
            case 'event-detail':
                element = {
                    name: 'event-detail',
                    label: location.state.selectedEventName || '--',
                    onClick: (val = false) => { }
                }
                break
            default:
                element = {}
        }
        return element
    }

    const createBreadCrumbNav = (paths) => {
        let newNavList = [...navList];
        paths.forEach(path => {
            if (navList.find(el => el.name === path)?.name) {
                // const indexOf = navList.findIndex(el => el.name === path)
                // const newCrumbData = manageDynamicCrumbData(path)
                // newNavList[indexOf] = newCrumbData
                // setNavList(newNavList)
                return
            }
            if (dynamicCrumbs.includes(path)) {
                newNavList.push(manageDynamicCrumbData(path))
            } else {
                let el = {
                    name: path,
                    label: crumbLabel[path],
                    // href:crumbHref[path]
                    onClick: (val = true) => {
                        if (val) {
                            const index = navList.findIndex(el => el.name === path)
                            setNavList(navList.slice(index + 1))
                            nav(crumbHref[path], { state: { ...location.state } })
                        }
                    }
                }
                newNavList.push(el)
            }

        })
        // console.log('here is nav list', newNavList)
        setNavList(newNavList)
    }

    return (
        <div className='main-section-wrapper'>
            <Breadcrumbs separator={<img src={assets.chevronRight} />} aria-label="breadcrumb">
                {navList?.length && navList.map((listItem, index) =>
                    <Link key={listItem.label} className={index === navList.length - 1 ? 'disable-click-current' : ''} underline="hover" color="inherit"
                        onClick={() => {
                            index === navList.length - 1 ?
                                listItem.onClick(false) :
                                listItem.onClick()
                        }}>
                        {index === 0 && <img src={assets.backArrow} />}
                        {listItem.label}
                    </Link>
                )}
            </Breadcrumbs>
            <Outlet>

            </Outlet>
            {isLoading && <Loader />}
        </div>
    )
}
