import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import CustomSearch from "../../components/custom-search/customSearch"
import Pagination from "../../components/pagination/pagination"
import Table from "../../components/table/table"
import { getFeedback } from "../../redux/feedback/feedbackSlice"
import { getFeedbackListSelector, getFeedbackLoadingState } from "../../redux/selectors/feedbackSelector"
import { assets } from "../../utils/assetsSrc"
import ReplyFeedback from "./reply-feedbck"
import ViewFeedback from "./view-feedback"
import './feedback.scss';
import Loader from "../../components/loader/loader"


export default function AdminFeedback() {

    const [viewFeedback, setViewFeedback] = useState(false)
    const [replyFeedback, setReplyFeedback] = useState(false)
    const [selectedRow, setSelectedRow] = useState({})
    const [feedbackConfig, setFeedbackConfig] = useState({
      params:{
        search:'',
        size:10,
        page:1
      }})

    const [feedbackData,setFeedbackData] = useState([])
    const feedbackListState = useSelector(getFeedbackListSelector())
    const dispatch = useDispatch()
    let searchTimer

    const columns = [
        {
          accessor: "name",
          Header: "Name",
        },
        {
          accessor: "email",
          Header: "Email",
        },
        {
            accessor: "subject",
            Header: "Subject",
            width: 100
        },
        {
            accessor: "text",
            Header: "Message",
            width: 100
        },
        {
          accessor: "",
          Header: "Action",
          Cell: ({ row }) => <div className='user-actions'>
            <img className='view' src={assets.eyeIcon} onClick = {() => {
              setViewFeedback(true)
              setSelectedRow(row.original)
            }}/>
            <img className='reply' src={assets.replyIcon} onClick = {() => {
              setReplyFeedback(true)
              setSelectedRow(row.original)
            }} />
        </div>
        },

    ]

    useEffect(() => {
      dispatch(getFeedback(feedbackConfig))
    }, [feedbackConfig])

    useEffect(() => {
      setFeedbackData(feedbackListState.results || [])
    },[feedbackListState])


    const cols = useMemo(() => columns, [columns])
    const rowData = useMemo(() => feedbackData, [feedbackData])

    const handleInputChange = (e) => {
        clearTimeout(searchTimer)
        searchTimer = setTimeout(()=>{
            const obj = {...feedbackConfig}
            obj.params.search = e.target.value
            setFeedbackConfig(obj)
        },1000)
    }

    const handlePageSizeChange = (pageSize) => {
      const config = { ...feedbackConfig }
      config.params.size = pageSize
      setFeedbackConfig(config)
    }
  
    const handlePageNavigation = (value) => {
      const config = { ...feedbackConfig }
      if (value === 'next') {
        config.params.page = config.params.page + 1
      } else if (value === 'prev') {
        config.params.page = config.params.page - 1
      }
      setFeedbackConfig(config)
    }

    return (
    <div>
        <div className="pl-search mt-4">
            {/* <img src={assets.filterIcon} /> */}
            <CustomSearch onChange={handleInputChange} width='400px' type='text' />
        </div>
        <div className='table mt-2'>
          <Table
            columns={cols}
            data={rowData}
            isLoading={false}
            height={'60vh'} />
        </div>
      <div className='pagination'>
        <Pagination
          pageSizeOptions={[10, 20, 40, 100]}
          totalCount={feedbackListState.count}
          currentPage={feedbackConfig.params.page}
          nextAndPrev={{ next: feedbackListState.next , prev: feedbackListState.prev }}
          handlePageSizeChangeHandler={handlePageSizeChange}
          handlePageNavigation={handlePageNavigation}
          />
      </div>

      {viewFeedback && <ViewFeedback
        label = 'User Feedback'
        show = {viewFeedback}
        setShow = {setViewFeedback}
        prefilledData = {selectedRow}
      />}

      {replyFeedback && <ReplyFeedback
        label = 'User Feedback'
        show = {replyFeedback}
        setShow = {setReplyFeedback}
        prefilledData = {selectedRow}
      />}
    </div>
    )
}