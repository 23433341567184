import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';


const riskAssessmentState = (state) => {
    return state.persistedReducer.riskAssessmentReducer;
}

// same input and output state to maintain architecture
// export const loginState = (appState) => createSelector(appState,(state)=>{
//     return state
// })

export const getproductLineStateSelector = () => createSelector(riskAssessmentState,(state)=>{
    return state?.productLineList || []
})

export const getproductModelStateSelector = () => createSelector(riskAssessmentState,(state)=>{
    return state?.productModelsList || []
})

export const getRiskAssessmentStateSelector = () => createSelector(riskAssessmentState,(state)=>{
    return state?.riskAssessments || []
})

export const getAllRiskAssessmentStateSelector = () => createSelector(riskAssessmentState,(state)=>{
    return state?.allRiskAssessments || []
})

export const getDeletedRiskAssessmentStateSelector = () => createSelector(riskAssessmentState,(state)=>{
    return state?.deletedRiskAssessments || []
})


export const getRiskAssessmentInfoStateSelector = () => createSelector(riskAssessmentState,(state)=>{
    return state?.riskAssessmentInfo || {}
})


export const getRiskAssessmentLoadingState = () => createSelector(riskAssessmentState,(state)=>{
    return state?.isLoading || false
})
