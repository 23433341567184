import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

const initialState = {
  isLoading: false,
  productLineList: [],
  productModelsList: [],
  riskAssessments: [],
  allRiskAssessments: [],
  selectedRiskAssessment: {},
  deletedRiskAssessments: [],
  riskAssessmentInfo: {},

};

const riskAssessmentSlice = createSlice({
  name: 'riskAssessment',
  initialState,
  reducers: {
    // get product lines
    getProductLines: state => {
      state.isLoading = true;
    },
    getProductLinesSuccess: (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      state.productLineList = response;
    },
    getProductLinesFailure: state => {
      state.isLoading = false;
    },

    // create product line
    createProductLine: state => {
      state.isLoading = true;
    },
    createProductLineSuccess: (state, action) => {
      state.isLoading = false;
      toast.success('Product Line created Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    createProductLineFailure: state => {
      state.isLoading = false;
    },

    editProductLine: state => {
      state.isLoading = true;
    },
    editProductLineSuccess: (state, action) => {
      state.isLoading = false;
      toast.success('Product Line edited Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    editProductLineFailure: state => {
      state.isLoading = false;
    },

    deleteProductLine: state => {
      state.isLoading = true;
    },
    deleteProductLineSuccess: (state, action) => {
      state.isLoading = false;
      toast.success('Product Line deleted Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    deleteProductLineFailure: (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      toast.error(response.message, {
        autoClose: 2000,
        className: 'toast-fail',
      });
    },

    // get product models
    getProductModels: state => {
      state.isLoading = true;
    },
    getProductModelsSuccess: (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      state.productModelsList = response;
    },
    getProductModelsFailure: state => {
      state.isLoading = false;
    },

    // create product models
    createProductModel: state => {
      state.isLoading = true;
    },
    createProductModelSuccess: (state, action) => {
      state.isLoading = false;
      toast.success('Product Model created Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    createProductModelFailure: state => {
      state.isLoading = false;
    },

    // edit product models
    editProductModel: state => {
      state.isLoading = true;
    },
    editProductModelSuccess: (state, action) => {
      state.isLoading = false;
      toast.success('Product Model edited Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    editProductModelFailure: state => {
      state.isLoading = false;
    },

    // delete product models
    deleteProductModel: state => {
      state.isLoading = true;
    },
    deleteProductModelSuccess: (state, action) => {
      state.isLoading = false;
      toast.success('Product Model deleted Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    deleteProductModelFailure: (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      toast.error(response.message, {
        autoClose: 2000,
        className: 'toast-fail',
      });
    },
    // clear product model data
    clearProductModelsData: state => {
      state.productModelsList = [];
    },

    //create Risk Assessment
    createRiskAssessment: state => {
      state.isLoading = true;
    },
    createRiskAssessmentSuccess: (state, action) => {
      state.isLoading = false;
      toast.success('Risk Assessment created Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    createRiskAssessmentFailure: state => {
      state.isLoading = false;
    },

    //Edit Risk Assessment
    editRiskAssessment: state => {
      state.isLoading = true;
    },
    editRiskAssessmentSuccess: (state, action) => {
      state.isLoading = false;
      toast.success('Risk Assessment edited Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    editRiskAssessmentFailure: state => {
      state.isLoading = false;
    },

    // fetch Risk Assessments
    getRiskAssessments: state => {
      state.isLoading = true;
    },
    getRiskAssessmentsSuccess: (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      state.riskAssessments = response;
      // toast.success('Risk Assessment Successfully', { autoClose: 2000, className: 'toast-success' })
    },
    getRiskAssessmentsFailure: state => {
      state.riskAssessments = [];
      state.isLoading = false;
    },

    // fetch same product line all Risk Assessments
    getAllRiskAssessments: state => {
      state.isLoading = true;
    },
    getAllRiskAssessmentsSuccess: (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      state.allRiskAssessments = response;
      // toast.success('Risk Assessment Successfully', { autoClose: 2000, className: 'toast-success' })
    },
    getAllRiskAssessmentsFailure: state => {
      state.riskAssessments = [];
      state.isLoading = false;
    },

    // fetch Risk Assessments
    getRiskAssessmentsById: state => {
      state.isLoading = true;
    },
    getRiskAssessmentsByIdSuccess: (state, action) => {
      state.isLoading = false;
      // toast.success('Risk Assessment Successfully', { autoClose: 2000, className: 'toast-success' })
    },
    getRiskAssessmentsByIdFailure: state => {
      state.isLoading = false;
    },

    // Delete Risk Assessment
    deleteRiskAssessment: state => {
      state.isLoading = true;
    },
    deleteRiskAssessmentSuccess: (state, action) => {
      state.isLoading = false;
      toast.success('Risk Assessment Deleted Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    deleteRiskAssessmentFailure: (state, action) => {
      state.isLoading = false;
    },

    // Deleted Risk Assessment
    deletedRiskAssessment: state => {
      state.isLoading = true;
    },
    deletedRiskAssessmentSuccess: (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      state.deletedRiskAssessments = response;
    },
    deletedRiskAssessmentFailure: (state, action) => {
      state.isLoading = false;
    },
    // restore Risk Assessment
    restoreRiskAssessment: state => {
      state.isLoading = true;
    },
    restoreRiskAssessmentSuccess: (state, action) => {
      state.isLoading = false;
    },
    restoreRiskAssessmentFailure: (state, action) => {
      state.isLoading = false;
    },

    //permanently delete Risk Assessment
    hardDeleteRiskAssessment: state => {
      state.isLoading = true;
    },
    hardDeleteRiskAssessmentSuccess: (state, action) => {
      state.isLoading = false;
      toast.success('Risk Assessment Deleted Successfully', {
        autoClose: 2000,
        className: 'toast-success',
      });
    },
    hardDeleteRiskAssessmentFailure: state => {
      state.isLoading = false;
    },

    //get risk assessment info
    getRiskAssessmentInfo: state => {
      state.isLoading = true;
    },

    getRiskAssessmentInfoSuccess: (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      state.riskAssessmentInfo = response;
    },

    getRiskAssessmentInfofailure: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default riskAssessmentSlice.reducer;
export const {
  // get product lines
  getProductLines,
  getProductLinesSuccess,
  getProductLinesFailure,

  // create product line
  createProductLine,
  createProductLineSuccess,
  createProductLineFailure,

  // edit product line
  editProductLine,
  editProductLineSuccess,
  editProductLineFailure,

  // delete product line
  deleteProductLine,
  deleteProductLineSuccess,
  deleteProductLineFailure,

  // get product models
  getProductModels,
  getProductModelsSuccess,
  getProductModelsFailure,

  // create product models
  createProductModel,
  createProductModelSuccess,
  createProductModelFailure,

  // edit product models
  editProductModel,
  editProductModelSuccess,
  editProductModelFailure,

  // delete product models
  deleteProductModel,
  deleteProductModelSuccess,
  deleteProductModelFailure,

  clearProductModelsData,
  // create risk assessment
  createRiskAssessment,
  createRiskAssessmentSuccess,
  createRiskAssessmentFailure,

  // edit risk assessment
  editRiskAssessment,
  editRiskAssessmentSuccess,
  editRiskAssessmentFailure,

  // get Risk Assessments
  getRiskAssessments,
  getRiskAssessmentsSuccess,
  getRiskAssessmentsFailure,

  //get product line all Risk Assessments
  getAllRiskAssessments,
  getAllRiskAssessmentsSuccess,
  getAllRiskAssessmentsFailure,

  // get Risk Assessment by
  getRiskAssessmentsById,
  getRiskAssessmentsByIdSuccess,
  getRiskAssessmentsByIdFailure,

  // delete risk assessment
  deleteRiskAssessment,
  deleteRiskAssessmentSuccess,
  deleteRiskAssessmentFailure,

  // deleted risk assessment
  deletedRiskAssessment,
  deletedRiskAssessmentSuccess,
  deletedRiskAssessmentFailure,

  // restore risk assessment
  restoreRiskAssessment,
  restoreRiskAssessmentSuccess,
  restoreRiskAssessmentFailure,

  //permanent delete risk assessment
  hardDeleteRiskAssessment,
  hardDeleteRiskAssessmentSuccess,
  hardDeleteRiskAssessmentFailure,

  //get risk assessment info
  getRiskAssessmentInfo,
  getRiskAssessmentInfoSuccess,
  getRiskAssessmentInfofailure,
} = riskAssessmentSlice.actions;
