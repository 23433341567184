import React from "react"
import "./add-note.scss"
import { assets } from "../../../../../utils/assetsSrc"

const AddNote = ({
  setShowNoteInput,
  showNoteInput,
  title,
  handleNoClicked,
  name,
  value,
  setFiledValue,
  readOnly
}) => {
  return (
    <div className="add-note-wrapper">
      {!showNoteInput ? ( !readOnly && (
        <span onClick={() => setShowNoteInput(true)}>
          <img src={assets.addFilled} />
          <span className="add-note-text">{title ?? "Add note"}</span>
        </span>
      )
      ) : (
        <div className="note-row">
          <label htmlFor="note-input">Note (optional)</label>
          <input
            id="note-input"
            value={value}
            onChange={e => {
              setFiledValue(name, e.target.value)
            }}
            type="text"
            name={name}
            disabled={readOnly}
          />
          {!readOnly && (
          <span style={{ display: "flex" }}>
            <img
              style={{ scale: "0.6" }}
              //onClick={() => handleNoClicked?.() ?? setShowNoteInput(false)}
              src={assets.checkIcon}
            />
            <img
              style={{ scale: "0.6" }}
              onClick={() => {
                setShowNoteInput(false)
                setFiledValue(name, "")
              }}
              src={assets.closeIcon}
            />
          </span>
          )}
        </div>
      )}
    </div>
  )
}

export default AddNote
