import { createSelector } from "@reduxjs/toolkit";

const termsListState = (state) => {
    return state.persistedReducer.termsConditionsReducer
}

export const getTermsListSelector = () => createSelector(termsListState,(state) =>{
    return state?.termsList || []
})

export const getTermsAndConditionsLoadingState = () => createSelector(termsListState,(state) =>{
    return state?.isLoading || false
})

const privacyListState = (state) => {
    return state.persistedReducer.termsConditionsReducer
}

export const getPrivacyListSelector = () => createSelector(privacyListState,(state) =>{
    return state?.privacyList || []
})

export const getPrivacyPolicyLoadingState = () => createSelector(privacyListState,(state) =>{
    return state?.isLoading || false
})