import React, { useState } from "react"
import "./event-details.scss"
import TextField from "../../../../../components/form/textfield/textfield"
import RadioButtonWithLabel from "./readio-button-with-label"
import AddNote from "../../components/add-note/add-note"
import Button from "../../../../../components/form/button/button"
import { Box, Chip } from "@mui/material"
import ViewInfo from "../../components/view-info/view-info"
import { eventDetailInfoIconEnum } from "../../../../../utils/constants"

const FinalRecommendation = ({ setFieldValue, values, readOnly }) => {
  const [showFinalNoteInput, setShowFinalNoteInput] = useState(values.internal_note?.length>0)

  const [showInternalNoteInput, setShowInternalNoteInput] = useState(values.final_note?.length>0)

  const [customTest, setCustomTest] = useState("")

  const [customTests, setCustomTests] = React.useState(values.new_task)

  const handleChange = e => {
    setCustomTest(e.target.value)
  }

  const addCustomTest = () => {
    if (customTest.trim().length > 0) {
      customTests.push(customTest)
      setCustomTests([...customTests])
      setFieldValue("new_task", [...customTests])
      setCustomTest("")
    }
  }
  const handleDeleteSelected = customTest => {
    const filteredData = customTests.filter(item => item !== customTest)
    setCustomTests(filteredData)
    setFieldValue("new_task", filteredData)
  }

  return (
    <div className="final-recommendation">
      <div className="heading">Final recommendations:</div>
      <RadioButtonWithLabel
        label={
          "Do the listed protective measures create any additional hazards?"
        }
        name={"additional_hazards"}
        setFieldValue={setFieldValue}
        value={values.additional_hazards}
        disabled={readOnly}
      />

      {!readOnly && (
      <TextField
        placeholder={"Add Custom Text"}
        label={"New task to risk assess"}
        name="customTest"
        type="text"
        value={customTest}
        onChange={handleChange}
        styles={{ marginTop: 20 }}
        rightElement={
          <Button type ="button"variant={"submit-button"} clickHandler={addCustomTest}>
            Add
          </Button>
        }
        disabled={readOnly}
      />
      )}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {!!customTests.length &&
          customTests.map(value => (
            <Chip
              onMouseDown={event => {
                event.stopPropagation()
              }}
              key={value}
              label={value}
              onDelete={() => handleDeleteSelected(value)}
            />
            // <span key={value}>{value}</span>
          ))}
      </Box>
      <div className="final-note-row">
      <AddNote
        showNoteInput={showFinalNoteInput}
        setShowNoteInput={setShowFinalNoteInput}
        title={"Add internal-only note"}
        name={"internal_note"}
        value={values.internal_note}
        setFiledValue={setFieldValue}
        readOnly={readOnly}
      />
      {/* <div className="separator" /> */}
      <div className="info-icon-center">
              <ViewInfo label={eventDetailInfoIconEnum.internally_only}/>
      </div>


      <AddNote
        showNoteInput={showInternalNoteInput}
        setShowNoteInput={setShowInternalNoteInput}
        title={"Add final note"}
        name={"final_note"}
        value={values.final_note}
        setFiledValue={setFieldValue}
        readOnly={readOnly}
      />
      </div>
    </div>
  )
}

export default FinalRecommendation
