import React from "react"

const TextArea = ({ label, height, rightElement, rightElementStyles, ...props }) => {
  return (
    <div style={{ position: "relative", marginBottom: "10px" }}>
      <label
        style={{
          position: "absolute",
          left: "10px",
          top: "-13px",
          background: "white",
          padding: "0 4px"
        }}
      >
        {label}
      </label>
      <textarea
        wrap="soft"
        style={{
          width: "100%",
          height: height||"88px",
          maxHeight: "190px",
          resize: "none",
          padding: "10px"
        }}
        {...props}
      />
      <div style={rightElementStyles}>{rightElement}</div>
    </div>
  )
}

export default TextArea
