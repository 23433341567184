import axios from 'axios';
import {call, put, take, takeLatest} from 'redux-saga/effects';
import {errorHandler} from '../../utils/errorHandling';

// API function import
import {apiFunctions} from '../../api/functions';

// Reducer import from loginSlice
import {
  loginSuccess,
  loginFailure,
  passwordResetEmailFailure,
  passwordResetEmailSuccess,
  passwordResetVerifyCodeSuccess,
  passwordResetVerifyCodeFailure,
  passwordResetPasswordChangeSuccess,
  passwordResetPasswordChangeFailure,
} from './loginSlice';

const loginAPI = apiFunctions.login;
const passwordResetEmailAPI = apiFunctions.passwordResetEmail;
const verifyCodeAPI = apiFunctions.verifyCode;
const passwordChangeAPI = apiFunctions.passwordChange;

// Login starts

async function loginHandler(payload) {
  return await loginAPI(payload);
}

function* login({payload}) {
  try {
    const result = yield call(loginHandler, payload);
    yield put(loginSuccess(result.data));
  } catch (error) {
    const err = errorHandler(error);
    yield put(loginFailure(err));
  }
}

export function* loginWatcher() {
  yield takeLatest('login/initLogin', login);
}

// Login ends

// Password reset starts
async function verifyEmailHandler(payload) {
  return await passwordResetEmailAPI(payload);
}
async function verifyCodeHandler(payload) {
  return await verifyCodeAPI(payload);
}
async function passwordChangeHandler(payload) {
  return await passwordChangeAPI(payload);
}

// [Forgot password] step 1
function* verifyEmail(payload) {
  try {
    const postPayload = payload.payload;
    const result = yield call(verifyEmailHandler, postPayload);
    yield put(
      passwordResetEmailSuccess({
        type: 'success',
        message: 'A verification code has been sent to your Email',
      }),
    );
  } catch (error) {
    const errorMsg = errorHandler(error);
    yield put(passwordResetEmailFailure(errorMsg));
  }
}
// [Forgot password] step2
function* verifyCode(payload) {
  try {
    const result = yield call(verifyCodeHandler, payload.payload);
    yield put(
      passwordResetVerifyCodeSuccess({
        type: 'success',
        message: 'Email Verified',
      }),
    );
  } catch (error) {
    const errorMsg = errorHandler(error);
    yield put(passwordResetVerifyCodeFailure(errorMsg));
  }
}
// [Forgot password] step3
function* changePassword(payload) {
  try {
    const result = yield call(passwordChangeHandler, payload.payload);
    yield put(
      passwordResetPasswordChangeSuccess({
        type: 'success',
        message: 'password changed',
      }),
    );
  } catch (error) {
    yield put(
      passwordResetPasswordChangeFailure({
        type: 'failure',
        message: '[password change] something went wrong',
      }),
    );
  }
}

// Watcher Saga
export function* passwordResetWatcher() {
  // verify email
  yield takeLatest('login/passwordResetEmailInit', verifyEmail);
  // code verification
  // const { payload } = yield take('login/passwordResetVerifyCodeInit')
  // yield call(verifyCode,payload)
  yield takeLatest('login/passwordResetVerifyCodeInit', verifyCode);
  // password change
  yield takeLatest('login/passwordResetPasswordChangeInit', changePassword);
  // const passwordChangePayload = yield take(
  //   'login/passwordResetPasswordChangeInit',
  // );
  // yield call(changePassword, passwordChangePayload.payload);
  // further actions goes here
}
