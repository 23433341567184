import { useSelector } from 'react-redux';
import Loader from '../../components/loader/loader';
import { getFeedbackLoadingState } from '../../redux/selectors/feedbackSelector';
import { getLoginStateSelector } from '../../redux/selectors/loginSelector';
import { getUserRoleName } from '../../utils/constants';
import AdminFeedback from './feedback-admin-type';
import UserFeedback from './feedback-user-type';
import './feedback.scss';


export default function Feedback() {

    const loginState = useSelector(getLoginStateSelector())
    const isLoading = useSelector(getFeedbackLoadingState())
    const { isLoggedIn, loggedInUser } = loginState

    return (
    <div className='feedback-wrapper'>
        <header className='header'>
        <div className='heading'>User Feedback</div>
      </header>
      {getUserRoleName(loggedInUser?.role) === "admin" ? <AdminFeedback /> : <UserFeedback /> }
      {isLoading && <Loader />}
    </div>
    )
}