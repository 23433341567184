import {toast} from 'react-toastify';
import Request from './axiosInstance';

const tokenInterceptor = Request.interceptors.request.use(
  req => {
    const token = localStorage.getItem('token');
    if (!!token) {
      req.headers['Authorization'] = `Token ${token}`;
    }
    return req;
  },
  null,
  {synchronous: true},
);

window.addEventListener('storage', () => {
  if (localStorage.getItem('token')) {
    Request.interceptors.request.eject(tokenInterceptor);
    Request.interceptors.request.use(tokenInterceptor);
  }
});

const catchErrors = err => {
  // window.localStorage.setItem('isTokenExired',false)
  if (
    err?.response?.status === 401 &&
    err?.response?.data?.detail === 'Invalid token.'
  ) {
    // toast.error('Your Login Session is Expired. Please Login Again',{autoClose:5000})
    // localStorage.clear()
    // window.location = '/login'
  } else if ([400, 500, 403, 401].find(e => e == err?.response?.status)) {
    // createCustomErrorMessage(err)
  }
  throw err;
};

export const get = (url, config) => {
  return Request.get(url, {
    params: config?.params,
    paramsSerializer: {
      indexes: null, // use brackets with indexes
    },
  }).catch(catchErrors);
};

export const post = (url, data, config) => {
  return Request.post(url, data, config).catch(catchErrors);
};

export const put = (url, config) => {
  return Request.put(url, config).catch(catchErrors);
};

export const patch = (url, data, config) => {
  return Request.patch(url, data, config).catch(catchErrors);
};

export const del = (url, data, config) => {
  return Request.delete(url, data, config).catch(catchErrors);
};
