import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks({
  data,
  type,
  handleValueChange,
  value,
}) {
  const [personName, setPersonName] = React.useState(
    data.filter(item => value.includes(item.id)) ?? [],
  );

  const handleChange = event => {
    const {
      target: {value},
    } = event;
    const selectedValue = typeof value === 'string' ? value.split(',') : value;
    setPersonName(selectedValue);
    handleValueChange(type, selectedValue);
  };

  return (
    <div>
      <FormControl sx={{m: 1, width: 150, background: 'white'}}>
        {/* <InputLabel id="multiple-checkbox-label">Select</InputLabel> */}
        <Select
          SelectDisplayProps={{style: {padding: 12}}}
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          // input={<OutlinedInput label="Select" />}
          // renderValue={selected => 'Select'}
          renderValue={(selected) => selected.map(item => item.name).join(', ')}
          MenuProps={MenuProps}
          variant="outlined">
          {data.map(item => (
            <MenuItem key={item.id} value={item}>
              <Checkbox checked={personName.indexOf(item) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
