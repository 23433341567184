import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import Button from '../../../../../components/form/button/button'
import { assets } from '../../../../../utils/assetsSrc'
import RiskAssessmentNestedTabs from '../../components/risk-assessment-nested-tabs/risk-assessment-nested-tabs'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { createTask, deleteTask, generateExcel, generateExcelId, getRiskAssessmentTasks } from '../../../../../redux/risk-assessment-core/riskAssessmentCoreSlice'
import AddEditTask from '../../components/addEditTask/addEditTask'
import { useSelector } from 'react-redux'
import { getRiskAssessmentCoreLoadingState, getRiskAssessmentCoreTasksState } from '../../../../../redux/selectors/riskAssessmentCore'
import TaskCard from '../../components/task-card/task-card'
import Loader from '../../../../../components/loader/loader'
import './specific-risk-assessment.scss'
import { allRoutes } from '../../../../../utils/routeConstants'
import { lifeCyclePhasesEnum, rbac, sortingEnum, taskFrequencyEnum, taskStatusIDS } from '../../../../../utils/constants'
import Share from '../../components/share/share'
import CustomSearch from '../../../../../components/custom-search/customSearch'
import { getLoginStateSelector } from '../../../../../redux/selectors/loginSelector'
import Filter from '../../components/filter/filter'
import SingleSelectCheckmarks from '../../components/single-select-checkbox/singleSelectCheckBox'

export default function SpecificRiskAssessment() {
    const location = useLocation()
    const dispatch = useDispatch()
    const nav = useNavigate()
    const taskList = useSelector(getRiskAssessmentCoreTasksState())
    const isLoading = useSelector(getRiskAssessmentCoreLoadingState())
    const userRoleId = useSelector(getLoginStateSelector())?.loggedInUser?.role
    const [riskAssessmentData, setRiskAssessmentData] = useState({})
    const [exportState, setExportState] = useState('Export')
    const [showAddTask, setShowAddTask] = useState(false)
    const [showEditTask, setShowEditTask] = useState(false)
    const [showSharePopup, setShowSharePopup] = useState(false)
    const [taskToBeDuplicated, setTaskToBeDuplicated] = useState(null)
    const [showFilter, setShowFilter] = useState(false)

    const [intervalId,setIntervalId] = useState()
    const [excelDetails, setExcelDetails] = useState()
    const [excelIdDetails, setExcelIdDetails] = useState()
    const [download,setDownload] = useState(false)
    const [riskAssessmentConfig, setRiskAssessmentConfig] = useState({
        params: { search: "",
                  life_cycle_phase: "",
                  frequency: "",
                  status: "",
                  ordering: "",
                }
        })
    let searchTimer

    useEffect(() => {
        const raData = location?.state?.selectedRiskAssessment
        setRiskAssessmentData(raData)
       // fetchTaskList(location?.state?.selectedRiskAssessment?.id)
    }, [])

    const fetchTaskList = (raId) => {
        const payload = {
            riskAssessmentId: raId,
            config: riskAssessmentConfig
        }
        dispatch(getRiskAssessmentTasks(payload))
    }

    useEffect(() => {
        fetchTaskList(location?.state?.selectedRiskAssessment?.id, riskAssessmentConfig)
    }, [riskAssessmentConfig])

    const handleTaskCreation = (payload) => {
        dispatch(createTask({
            payload,
            callback: (resp) => {
                setShowAddTask(false)
                setShowEditTask(false)
                fetchTaskList(riskAssessmentData.id)
            }
        }))
    }

    const handleTaskSelection = (taskData) => {
        nav(allRoutes.dashboardRoutes.assessmentRoutes.taskDetails, {
            state: {
                ...location.state,
                selectedTask: taskData
            }
        })
    }

    const handleTaskDelete = (id) => {
        dispatch(deleteTask({
            id,
            callback: (resp) => {
                fetchTaskList(riskAssessmentData.id)
            }
        }))
    }

    const handleTaskToBeDuplicated = (taskData) => {
        const values = {
            taskName: taskData.name,
            riskAssessmentList: taskData.risk_assessment_detail,
            taskDetails: taskData.details,
            lifeCyclePhases: lifeCyclePhasesEnum.find(el=>el.id===taskData.life_cycle_phase),
            //subCategories: {id:taskData.sub_categories,name:taskData.sub_category_name},
            task_sub_categories:taskData.task_sub_categories,
            taskFrequency: taskFrequencyEnum.find(el=>el.id===taskData.frequency),
            liveWork: taskData.live_work
        }
        setTaskToBeDuplicated(values)
    }

    const exportClickHandler = () => {
        if(exportState === "Download"){
            setDownload(true)
        }
        else{
            setDownload(false)
            const payload = {
                risk_assessment_id:riskAssessmentData.id
            }
            dispatch(generateExcelId({
                payload,
                callback: (resp) => {
                    setExcelIdDetails(resp)
            }}))
            setExportState('Preparing...')
        }
    }

    useEffect(() => {
        if(excelIdDetails){
        let interval = setInterval(() => dispatch(generateExcel({
            payload: {
                id: excelIdDetails?.unique_id
            },
            callback: (resp) => {
                setExcelDetails(resp)
        }})), 2000);
        setIntervalId(interval)
    }
    }, [excelIdDetails]);

    useEffect(() => {
        if(excelDetails?.status === "Completed") {
            setExportState("Download")
            clearInterval(intervalId)
        }
        else if(excelDetails?.status === "Failed") {
            setExportState("Export")
            clearInterval(intervalId)
            setExcelIdDetails(undefined)
        }
    }, [excelDetails])

    const handleInputChange = e => {
        clearTimeout(searchTimer)
        searchTimer = setTimeout(() => {
          const obj = { ...riskAssessmentConfig }
          obj.params.search = e.target.value
          setRiskAssessmentConfig(obj)
        }, 1000)
      }

    const handleOrderingChange = e => {
        const obj = { ...riskAssessmentConfig }
        obj.params.ordering = e.id
        setRiskAssessmentConfig(obj)
    }

    const handleFilterChange = payload => {
        setShowFilter(false)
        const obj = { ...riskAssessmentConfig }
        obj.params.life_cycle_phase = payload.life_cycle_phase
        obj.params.frequency = payload.frequency
        obj.params.status = payload.status
        setRiskAssessmentConfig(obj)
    }

    return (
        <div className='specific-risk-assessment-wrapper'>
            <header>
                <div className='progress-header'>
                    {`Progress: ${taskList?.filter(item=>item.task_status===taskStatusIDS.completed)?.length??0}/${taskList?.length??0} task assessed`}
                </div>
                <div className="pl-search">
                    <CustomSearch
                        onChange={handleInputChange}
                        width="400px"
                        type="text"
                    />
                    <div className='sorting'>
                        <SingleSelectCheckmarks data={sortingEnum} handleOrderingChange = {handleOrderingChange}/>
                    </div>
                    <div className='filter' onClick={() => {setShowFilter(true)}}>
                        <img src={assets.filterIcon}/> Filter </div>
                </div>
            </header>
            <article>
                <div className='assessment-details'>
                    <Row>
                        <Col className='details' xs={5}>
                            <div className='heading'>{riskAssessmentData?.name}</div>
                            <div className='pl-list'>Product-line: {riskAssessmentData?.product_detail?.name}</div>
                            <div className='pm-list'>Model: {riskAssessmentData?.machine_detail?.map(el => el.name).join(', ')}</div>
                        </Col>
                        <Col xs={7}>
                            <div className='ra-actions'>
                                <a href={excelDetails?.file} download = {download} >
                                    <button className='export-btn' onClick = {exportClickHandler}>
                                        <img style={{marginRight: '15px'}} src={assets.excelIcon} />
                                        {exportState}
                                    </button>
                                </a>
                                <Button variant='secondary-button' clickHandler={()=>setShowSharePopup(true)}><img style={{ marginRight: '15px' }} src={assets.shareIcon} />Share</Button>
                                {rbac.addTask(userRoleId) && (
                                    <Button variant='submit-button' clickHandler={() => setShowAddTask(true)}><img style={{ marginRight: '15px' }} src={assets.plusCircle} />Add Task</Button>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row></Row>
                </div>
                <div className='nested-tabs'>
                    <RiskAssessmentNestedTabs />
                </div>
            </article>
            <main>
                {taskList.map(task =>
                    <TaskCard key={task.id} data={task} handleTaskSelection={() => handleTaskSelection(task)}
                        handleTaskDelete={() => handleTaskDelete(task.id)}
                        handleTaskDuplicate={() => {setShowEditTask(true);handleTaskToBeDuplicated(task)}} />
                )}
            </main>
            <AddEditTask
                label='New Task'
                show={showAddTask}
                setShow={setShowAddTask}
                handleTaskCreation={handleTaskCreation}
            />
            {showEditTask&&<AddEditTask
                label='Duplicate Risk Task'
                show={showEditTask}
                setShow={setShowEditTask}
                prefilledData={taskToBeDuplicated}
                handleTaskCreation={handleTaskCreation}
            />}
            {showSharePopup && <Share 
            show={showSharePopup}
            setShow={setShowSharePopup}
            riskAssessmentId={location?.state?.selectedRiskAssessment?.id}
            linkName={riskAssessmentData?.name}
            />}
            {showFilter  && <Filter
                label="Filter"
                show={showFilter}
                setShow={setShowFilter}
                handleFilterChange={handleFilterChange}
                config={riskAssessmentConfig.params}
            />
            }
            {isLoading && <Loader />}
        </div>
    )
}
