import React, {useEffect, useMemo, useState} from 'react';
import './summary.scss';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  getRiskAssessmentCoreLoadingState,
  getSummaryData,
} from '../../../../../redux/selectors/riskAssessmentCore';
import {useSelector} from 'react-redux';
import {
  getSummary,
  getTaskDetail,
} from '../../../../../redux/risk-assessment-core/riskAssessmentCoreSlice';
import {assets} from '../../../../../utils/assetsSrc';
import {getStatus, taskStatusEnum} from '../../../../../utils/constants';
import Table from '../../../../../components/table/table';
import TaskMedia from '../task-details/task-media/task-media';
import InternalNotes from '../task-details/internal-notes/internal-notes';
import Pagination from '../../../../../components/pagination/pagination';
import {statusEnum} from '../../../../../utils/constants';
import {Col, Form} from 'react-bootstrap';
import MultipleSelectCheckmarks from './mutli-select-checkbox';
import {Checkbox} from '@mui/material';
import CustomSearch from './customSearch';
import debounce from 'lodash.debounce';
import {allRoutes} from '../../../../../utils/routeConstants';
import Loader from '../../../../../components/loader/loader';
import {toast} from 'react-toastify';

const Summary = () => {
  const nav = useNavigate();

  const dispatch = useDispatch();
  const [search, setSearchText] = useState('');

  const [showTaskMedia, setShowTaskMedia] = useState(false);
  const [taskMedia, setTaskMedia] = useState();
  const [showNotes, setShowNotes] = useState(false);
  const [internalNotes, setInternalNotes] = useState([]);

  const location = useLocation();
  const selectedRAId = location.state.selectedRiskAssessment?.id;
  const isLoading = useSelector(getRiskAssessmentCoreLoadingState());
  const summaryData = useSelector(getSummaryData());
  const [summaryConfig, setSummaryConfig] = useState({
    params: {
      size: 10,
      page: 1,
      parent_risk_assessment: selectedRAId,
      search: '',
      status: [],
      risk_ranking_consideration: [],
      action: undefined,
    },
  });

  const fetchSummary = () => {
    const payload = {
      riskAssessmentId: selectedRAId,
      config: summaryConfig,
    };
    dispatch(getSummary(payload));
  };

  useEffect(() => {
    if (summaryConfig) fetchSummary();
  }, [summaryConfig]);

  const handleInternalNotes = notes => {
    const notesData = notes.filter(item => item.internal_note.length > 0);
    if (notesData.length > 0) {
      setInternalNotes(notesData);
      setShowNotes(true);
    }
  };

  const onHandleActionChange = event => {
    const config = {...summaryConfig};
    config.params.action = event.target.checked === true ? true : undefined;
    setSummaryConfig(config);
  };
  const getEventRiskStyle = status => {
    switch (status) {
      case 1:
        return {background: '#EC043C'};
      case 2:
        return {background: '#FF6B00'};
      case 3:
        return {background: '#FAFF00', color: '#000'};
      case 4:
        return {background: '#23B100'};
      default:
        return {background: '#23B100'};
    }
  };

  const getStatusIcon = taskStatus => {
    switch (taskStatus) {
      case 2:
        return assets.taskCompleted;
      case 1:
        return assets.taskInProgress;
      case 3:
        return assets.taskNotStarted;
      default:
        return assets.taskNotStarted;
    }
  };
  const handlePageSizeChange = pageSize => {
    const config = {...summaryConfig};
    config.params.size = pageSize;
    setSummaryConfig(config);
  };

  const handlePageNavigation = value => {
    const config = {...summaryConfig};
    if (value === 'next') {
      config.params.page = config.params.page + 1;
    } else if (value === 'prev') {
      config.params.page = config.params.page - 1;
    }
    setSummaryConfig(config);
  };

  const handleInputChange = e => {
    // setSearchText(e.target.value);
    const obj = {...summaryConfig};
    obj.params.search = e.target.value;
    obj.params.page = 1;
    setSummaryConfig(obj);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleInputChange, 1000);
  }, []);

  const handleValueChange = (type, value) => {
    const config = {...summaryConfig};
    const id = value.map(item => {
      return item.id;
    });
    if (type === 'status') {
      config.params.status = id;
    } else if (type === 'event_risk') {
      config.params.risk_ranking_consideration = id;
    }
    setSummaryConfig(config);
  };

  const onRowClick = data => {
    const id = data.task_id;
    dispatch(
      getTaskDetail({
        id,
        callback: resp => {
          nav(allRoutes.dashboardRoutes.assessmentRoutes.taskDetails, {
            state: {
              ...location.state,
              selectedTask: resp,
            },
          });
        },
      }),
    );
  };
  const columns = [
    {
      accessor: 'event_risk',
      Header: 'Highest Event Risk',
      Cell: ({row}) => (
        <div
          className="event-risk"
          style={getEventRiskStyle(row.original.event_risk)}>
          {getStatus(row.original.event_risk)}
        </div>
      ),
    },
    {
      accessor: 'task_name',
      Header: 'Task Name',
      disableSortBy: true,
      width: 500,
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: ({row}) => (
        <img className="viewMedia" src={getStatusIcon(row.original.status)} />
      ),
    },

    {
      accessor: '',
      Header: 'Action',
      Cell: ({row}) =>
        row.original.action ? (
          <img className="viewMedia" src={assets.actionRequire} />
        ) : (
          <></>
        ),
    },
    {
      accessor: 'critical_risk',
      Header: 'Critical/High Risk',
    },
    {
      accessor: '',
      Header: 'View media',
      disableSortBy: true,
      Cell: ({row}) => (
        <div>
          <img
            className="viewMedia"
            src={assets.viewMedia}
            onClick={e => {
              e.stopPropagation();
              if (row.original.media_details.length > 0) {
                setTaskMedia(row.original.media_details);
                setShowTaskMedia(true);
              } else {
                toast.error('No media available', {
                  autoClose: 2000,
                  className: 'toast-fail',
                });
              }
            }}
          />
        </div>
      ),
    },
    {
      accessor: '',
      Header: 'Notes',
      disableSortBy: true,
      width: 200,
      Cell: ({row}) => (
        <span
          onClick={e => {
            e.stopPropagation();
            handleInternalNotes(row.original.internal_note);
          }}
          className="view-notes-text">{`View Internal Notes(${
          row.original.internal_note.filter(
            item => item.internal_note.length > 0,
          ).length
        })`}</span>
      ),
    },
  ];

  const filterColumns = [
    {
      render: (
        <MultipleSelectCheckmarks
          data={statusEnum}
          type={'event_risk'}
          handleValueChange={handleValueChange}
          value={summaryConfig.params.risk_ranking_consideration}
        />
      ),
    },
    {
      render: (
        <CustomSearch
          onChange={debouncedResults}
          value={summaryConfig.params.search}
        />
      ),
    },
    {
      render: (
        <MultipleSelectCheckmarks
          data={taskStatusEnum}
          type={'status'}
          handleValueChange={handleValueChange}
          value={summaryConfig.params.status}
        />
      ),
    },
    {
      render: (
        <div className="action-require">
          <Checkbox
            onChange={onHandleActionChange}
            checked={summaryConfig.params.action ?? false}
          />
          Action required
        </div>
      ),
    },
    null,
    null,
    null,
  ];

  return (
    <div className="summary-wrapper">
      <div className="heading">Summary</div>
      {summaryData.results && (
        <>
          <div className="summary-table">
            <Table
              columns={columns}
              data={summaryData.results}
              isLoading={false}
              height={'68vh'}
              disabledSort={false}
              disabledFilter={false}
              filters={filterColumns}
              onRowClick={onRowClick}
            />
          </div>
          <div className="pagination">
            <Pagination
              pageSizeOptions={[10, 20, 40, 100]}
              totalCount={summaryData.count}
              currentPage={summaryConfig.params.page}
              nextAndPrev={{next: summaryData.next, prev: summaryData.prev}}
              handlePageSizeChangeHandler={handlePageSizeChange}
              handlePageNavigation={handlePageNavigation}
            />
          </div>
        </>
      )}
      {showTaskMedia && (
        <TaskMedia
          medias={taskMedia}
          show={showTaskMedia}
          setShow={() => setShowTaskMedia(false)}
        />
      )}
      {showNotes && (
        <InternalNotes
          show={showNotes}
          setShow={() => setShowNotes(false)}
          label="Internal Notes"
          notes={internalNotes}
        />
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default Summary;
