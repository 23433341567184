import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '../../../components/form/button/button';
import Table from '../../../components/table/table';
import {
  approveUser,
  deleteUser,
  editUser,
  getUsers,
} from '../../../redux/user-list/userListSlice';
import {getUserListSelector} from '../../../redux/selectors/userList';
import CustomSearch from '../../../components/custom-search/customSearch';
import {assets} from '../../../utils/assetsSrc';
import {useNavigate} from 'react-router-dom';
import './user-list.scss';
import {allRoutes} from '../../../utils/routeConstants';
import {getUserRoleName, getUserStatus} from '../../../utils/constants';
import Pagination from '../../../components/pagination/pagination';
import Loader from '../../../components/loader/loader';
import Confirmation from '../../../components/confirmation/confirmation';
import EditUser from './edit-user';
import {width} from '@mui/system';
import { getLoginStateSelector } from '../../../redux/selectors/loginSelector';

export default function UserList() {
  const [userData, setUserData] = useState([]);
  const [userListConfig, setUserListConfig] = useState({
    params: {
      search:'',
      size: 10,
      page: 1,
    },
  });
  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const userListState = useSelector(getUserListSelector());
  const loginState = useSelector(getLoginStateSelector())
  const {loggedInUser}  = loginState
  const nav = useNavigate();
  const dispatch = useDispatch();
  let searchTimer

  const columns = [
    {
      accessor: 'fullname',
      Header: 'Name',
      width: 200,
    },
    {
      accessor: 'email',
      Header: 'Email',
      width: 200,
    },
    {
      accessor: 'job_title',
      Header: 'Job Title',
      width: 200,
    },
    {
      accessor: 'role',
      Header: 'Role',
      width: 200,
      Cell: ({row}) => (
        <span style={{textTransform: 'capitalize'}}>
          {getUserRoleName(row.original.role)}
        </span>
      ),
    },
    // {
    //   accessor: "",
    //   Header: "Product Lines",
    //   width: 200,
    //   Cell: ({ row }) => <span onClick={() =>
    //     nav(allRoutes.dashboardRoutes.userList.productLine, { state: { data: row.original } })}
    //     className='view-list-text'>View List</span>
    // },
    // {
    //   accessor: "",
    //   Header: "Restricted Access Risk Assessments",
    //   width: 200,
    //   Cell: ({ row }) => <span onClick={() =>
    //     nav(allRoutes.dashboardRoutes.userList.restrictedRA, { state: { data: row.original } })}
    //     className='view-list-text'>View List</span>
    // },
    {
      accessor: '',
      Header: 'Status',
      width: 200,
      Cell: ({row}) => (
        <span style={{textTransform: 'capitalize'}}>
          {getUserStatus(row.original.status || 1)}
        </span>
      ),
    },
    {
      accessor: '',
      Header: 'Action',
      width: 200,
      Cell: ({row}) => 
        row.original.status === 1 ? (
          <div className="user-actions">
            <img className="approve" src={assets.checkIcon} onClick={() => handleApproveUser(row.original)}/>
            <img className="reject" src={assets.closeIcon} onClick={() => handleRejectUser(row.original)}/>
          </div>
        ) : (
          loggedInUser.id !== row.original.id && (
          <div className="user-actions">
            <img className="edit" src={assets.editIcon} onClick={() => {
                setSelectedRow(row.original);
                setShowEditUser(true);
              }} />
            <img className="delete" src={assets.deleteIcon} onClick={() => {
                setSelectedRow(row.original);
                setShowConfirmationPopUp(true);
              }} />
          </div> )
        ) 
    },
  ];

  useEffect(() => {
    dispatch(getUsers(userListConfig));
  }, []);

  useEffect(() => {
    setUserData(userListState.userList.results || []);
  }, [userListState]);

  useEffect(() => {
    dispatch(getUsers(userListConfig));
  }, [userListConfig]);

  const cols = useMemo(() => columns, [columns]);
  const rowData = useMemo(() => userData, [userData]);

  const handlePageSizeChange = pageSize => {
    const config = {...userListConfig};
    config.params.size = pageSize;
    config.params.page = 1;
    setUserListConfig(config);
  };

  const handlePageNavigation = value => {
    const config = {...userListConfig};
    if (value === 'next') {
      config.params.page = config.params.page + 1;
    } else if (value === 'prev') {
      config.params.page = config.params.page - 1;
    }
    setUserListConfig(config);
  };

  const handleInputChange = (e) => {
    clearTimeout(searchTimer)
    searchTimer = setTimeout(()=>{
        const obj = {...userListConfig}
        obj.params.search = e.target.value
        setUserListConfig(obj)
    },1000)
}

  const handleUserEditConfirm = payload => {
    const id = selectedRow.id;
    dispatch(
      editUser({
        id,
        payload,
        callback: resp => {
          setShowEditUser(false);
          dispatch(getUsers(userListConfig));
        },
      }),
    );
  };

  const handleUserDeleteConfirm = () => {
    const id = selectedRow.id;
    setShowConfirmationPopUp(false);
    dispatch(
      deleteUser({
        id,
        callback: resp => {
          dispatch(getUsers(userListConfig));
        },
      }),
    );
  };

  const handleApproveUser = (row) => {
    console.log(row)
    const id = row?.id;
    const payload ={
      status: 2
    }
    dispatch(approveUser({
      id,
      payload,
      callback: resp => {
        dispatch(getUsers(userListConfig));
      },
    }))
  }

  const handleRejectUser = (row) => {
    const id = row?.id
    const payload ={
      status: 3
    }
    dispatch(approveUser({
      id,
      payload,
      callback: resp => {
        dispatch(getUsers(userListConfig));
      },
    }))
    
  }

  return (
    <div className="user-list-wrapper">
      <header className="header">
        <div className="heading">User List</div>
        {/* <div className="button">
          <Button variant={'submit-button'}>Add New User</Button>
        </div> */}
        <div className="search-filter">
          <CustomSearch onChange={handleInputChange} width={'380px'} />
      </div>
      </header>
      <div className="table">
        <Table
          columns={cols}
          data={rowData}
          isLoading={false}
          height={'60vh'}
        />
      </div>
      <div className="pagination">
        <Pagination
          pageSizeOptions={[10, 20, 40, 100]}
          totalCount={userListState.userList.count}
          currentPage={userListConfig.params.page}
          nextAndPrev={{
            next: userListState.userList.next,
            prev: userListState.userList.previous,
          }}
          handlePageSizeChangeHandler={handlePageSizeChange}
          handlePageNavigation={handlePageNavigation}
        />
      </div>
      {showEditUser && (
        <EditUser
          label="Edit User"
          show={showEditUser}
          setShow={setShowEditUser}
          prefilledData={selectedRow}
          onClickConfirm={handleUserEditConfirm}
        />
      )}
      {showConfirmationPopUp && (
        <Confirmation
          label={'Delete User'}
          show={showConfirmationPopUp}
          setShow={setShowConfirmationPopUp}
          confirmationType={'typeDelete'}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          primaryButtonClickHandler={handleUserDeleteConfirm}
          secondaryButtonClickHandler={() => setShowConfirmationPopUp(false)}
        />
      )}
      {userListState.isLoading && <Loader />}
    </div>
  );
}
