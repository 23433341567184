import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getLoginStateSelector } from '../../../../../redux/selectors/loginSelector'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../../../components/loader/loader'
import { allRoutes } from '../../../../../utils/routeConstants'
import { useDispatch } from 'react-redux'
import { getRiskAssessmentsById } from '../../../../../redux/risk-assessment/riskAssessmentSlice'
import { getRiskAssessmentLoadingState } from '../../../../../redux/selectors/riskAssessment'

export default function ShareRiskAssessment() {
    const [riskAssessmentData, setRiskAssessmentData] = useState('')
    const loginState = useSelector(getLoginStateSelector())
    const loadingState = useSelector(getRiskAssessmentLoadingState())
    const params = useParams()
    const nav = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        getRiskAssessmentDetails(params.RAId)
    }, [])

    useEffect(() => {
        if (riskAssessmentData) {
            if (loginState.isLoggedIn && loginState.token) {
                nav(allRoutes.dashboardRoutes.assessmentRoutes.specificRiskAssessment,
                    {
                        state: {
                            lastNavLink: window.location.href,
                            selectedRiskAssessment: riskAssessmentData
                        }
                    })
            } else {
                nav(allRoutes.login, {
                    state: {
                        lastNavLink: window.location.href,
                        selectedRiskAssessment: riskAssessmentData
                    }
                })
            }
        }
    }, [riskAssessmentData])

    const getRiskAssessmentDetails = (id) => {
        dispatch(getRiskAssessmentsById({
            id, callback: (result) => {
                setRiskAssessmentData(result.data)
            }
        }))
    }
    return (
        <div>
            {loadingState && <Loader />}
        </div>
    )
}
