import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';


const userListState = (state) => {
    return state.persistedReducer.userListReducer;
}

export const getUserListSelector = () => createSelector(userListState,(state)=>{
    return state
})