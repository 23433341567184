import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import './singleSelectDropdown.scss';
import {ErrorMessage} from 'formik';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SingleSelectDropdown({
  label,
  listItems,
  value,
  handleSelectedUsersChange,
  name,
  showError,
}) {
  const theme = useTheme();
  const [listOfItemsDisplayed, setListOfItemsDisplayed] =
    React.useState(listItems);
  const [selectedUsers, setSelectedUsers] = React.useState('');

  const handleChange = event => {
    const {
      target: {value},
    } = event;
    setSelectedUsers(value);
    handleSelectedUsersChange(value);
  };

  // TODO:-
  React.useEffect(() => {
    setListOfItemsDisplayed(listItems);
  }, [listItems]);

  // React.useEffect(() => {
  //   handleSelectedUsersChange(selectedUsers);
  // }, [selectedUsers]);
  React.useEffect(() => {
    if(!value){
      setSelectedUsers('')
    }
    else if (value?.id) {
      setSelectedUsers(value);
    }
  }, [value]);

  return (
    <div className="mb-4">
      <FormControl sx={{m: 0, width: '100%'}}>
        {/* <InputLabel id="demo-multiple-chip-label">Chip</InputLabel> */}
        <div className="single-select-chip">
          <label htmlFor="demo-simple-select-label">{label}</label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select-label"
            name="demo-simple-select-label"
            value={selectedUsers}
            defaultValue=""
            onChange={handleChange}
            style={{width: '100%'}}
            //   input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={value => value.fullname || value.name}
            MenuProps={MenuProps}>
            {listOfItemsDisplayed.map(item => (
              <MenuItem
                key={item.id}
                value={item}
                // style={getStyles(item.name, personName, theme)}
              >
                {item.fullname || item.name}
              </MenuItem>
            ))}
          </Select>
          {showError && (
            <ErrorMessage component="div" name={name} className="error" />
          )}
        </div>
      </FormControl>
    </div>
  );
}
