import { call, put, take, takeLatest } from "redux-saga/effects"
import { apiFunctions } from "../../api/functions"
import { errorHandler } from "../../utils/errorHandling"
import {
     sendFeedbackSuccess, sendFeedbackFailure, getFeedbackSuccess, getFeedbackFailure,
     sendFeedbackReplySuccess,sendFeedbackReplyFailure
} from "./feedbackSlice"

//API

const sendFeedbackAPI = apiFunctions.sendFeedback
const getFeedbackAPI = apiFunctions.getFeedback
const sendFeedbackReplyAPI = apiFunctions.sendFeedbackReply


async function sendFeedbackHandlerAPI(payload){
    return await sendFeedbackAPI(payload)
}

function* sendFeedbackHandler({payload}) {
    try{
        const result = yield call(sendFeedbackHandlerAPI, payload)
        yield put(sendFeedbackSuccess(result.data))
    }
    catch (error){
        const err = errorHandler(error)
        yield put(sendFeedbackFailure(err))
    }
}

async function getFeedbackHandlerAPI(payload) {
    return await getFeedbackAPI(payload)
}

function* getFeedbackHandler({payload}) {
    try{
        const result = yield call(getFeedbackHandlerAPI, payload)
        yield put(getFeedbackSuccess(result.data))
    }
    catch (error){
        const err = errorHandler(error)
        yield put(getFeedbackFailure(err))
    }
}

async function sendFeedbackReplyHandlerAPI(payload){
    return await sendFeedbackReplyAPI(payload)
}

function* sendFeedbackReplyHandler({payload}) {
    try{
        const result = yield call(sendFeedbackReplyHandlerAPI, payload)
        yield put(sendFeedbackReplySuccess(result.data))
    }
    catch (error){
        const err = errorHandler(error)
        yield put(sendFeedbackReplyFailure(err))
    }
}


export function* feedbackWatchers() {
  yield takeLatest("feedback/sendFeedback", sendFeedbackHandler)
  yield takeLatest("feedback/getFeedback", getFeedbackHandler)
  yield takeLatest("feedback/sendFeedbackReply", sendFeedbackReplyHandler)
}
