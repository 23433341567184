import axios from "axios";

const urls = process.env.ALLOWED_HOSTS ? JSON.parse(process.env.ALLOWED_HOSTS) : [];
const deployURL = urls[0];

const axiosConfig = {
    baseURL: deployURL
}

export default axios.create()
// export default axios.create(axiosConfig)

