import React, { useEffect, useState } from "react"

import { useSelector } from "react-redux"

import "./deletedRiskAssessment.scss"
import {
  getDeletedRiskAssessmentStateSelector,
  getRiskAssessmentLoadingState
} from "../../../redux/selectors/riskAssessment"
import Loader from "../../../components/loader/loader"
import CustomSearch from "../../../components/custom-search/customSearch"
import { assets } from "../../../utils/assetsSrc"
import {
  deletedRiskAssessment,
  hardDeleteRiskAssessment,
  restoreRiskAssessment
} from "../../../redux/risk-assessment/riskAssessmentSlice"
import { useDispatch } from "react-redux"
import RiskAssessmentCard from "../risk-assessment/components/risk-assessment-card.js/risk-assessment-card"

export default function DeletedRiskAssessment() {
  const [deletedRiskAssessmentConfig, setDeletedRiskAssessmentConfig] =
    useState({
      params: {
        search: ""
      }
    })
  let searchTimer

  const isLoading = useSelector(getRiskAssessmentLoadingState())
  const deleteRiskAssessment = useSelector(
    getDeletedRiskAssessmentStateSelector()
  )
  const dispatch = useDispatch()
  useEffect(() => {
    fetchDeleteRa(deletedRiskAssessmentConfig)
  }, [deletedRiskAssessmentConfig])

  const fetchDeleteRa = config => {
    dispatch(deletedRiskAssessment(config))
  }

  const handleRestore = id => {
    dispatch(
      restoreRiskAssessment({
        id,
        callback: () => {
          fetchDeleteRa()
        }
      })
    )
  }

  const handlePermanentDelete = id => {
    dispatch(
      hardDeleteRiskAssessment({
        id,
        callback: () => {
          fetchDeleteRa()
        }
      })
    )
  }

  const handleInputChange = e => {
    clearTimeout(searchTimer)
    searchTimer = setTimeout(() => {
      const obj = { ...deletedRiskAssessmentConfig }
      obj.params.search = e.target.value
      setDeletedRiskAssessmentConfig(obj)
    }, 1000)
  }

  return (
    <div className="deleted-risk-assessment-list-wrapper">
      <header>
        <div className="header">
          <div>Deleted Assessment</div>
        </div>
        <div className="ra-search">
          {/* <img src={assets.filterIcon} /> */}
          <CustomSearch
            onChange={handleInputChange}
            width="300px"
            type="text"
          />
        </div>
      </header>
      <div className="delete-risk-assessment-list">
        {deleteRiskAssessment.map(ra => (
          <RiskAssessmentCard
            key={ra.id}
            data={ra}
            isDeleted={true}
            handleRestore={() => {
              handleRestore(ra.id)
            }}
            handlePermanentDelete={() => {
              handlePermanentDelete(ra.id)
            }}
          />
        ))}
      </div>

      {isLoading && <Loader />}
    </div>
  )
}
