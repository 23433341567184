import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';


const utilityState = (state) => {
    return state.persistedReducer.utilityReducer;
}

// same input and output state to maintain architecture
// export const utilityState = (appState) => createSelector(appState,(state)=>{
//     return state
// })

export const getUtilityStateSelector = () => createSelector(utilityState,(state)=>{
    return state
})