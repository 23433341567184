import { Form, Formik } from "formik"
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import Button from "../../../components/form/button/button";
import TextField from "../../../components/form/textfield/textfield";
import Loader from "../../../components/loader/loader";
import { changePassword } from "../../../redux/password/passwordSlice";
import { getPasswordLoadingState } from "../../../redux/selectors/passwordSelector";
import "./changePassword.scss"

export default function ChangePassword() {

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  }
  const dispatch = useDispatch();
  const isLoading = useSelector(getPasswordLoadingState())

  const validate = Yup.object({
    currentPassword: Yup.string().required("Current Password Required!"),
    newPassword: Yup.string().required("New Password Required!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Password must match")
      .required("Confrm Password Required!")
  })

  const handleChangePassword = (values, {resetForm}) => {
    const payload = {
      old_password: values.currentPassword,
      new_password: values.newPassword,
      confirm_password: values.confirmPassword
    }
    dispatch(changePassword(payload))
    resetForm()
  }

  return (
    <div className="change-password-wrapper">
      <header className="header mb-5">
        <div className="heading">Change Password</div>
      </header>
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={handleChangePassword}
      >
        {({ formik, resetForm }) => (
          <Form>
            <div className="password-change-form ">
              <TextField
                type="password"
                label="Current Password*"
                name="currentPassword"
                placeholder="Enter current password"
                suffixIcon="closeEye"
              />
              <TextField
                type="password"
                label="New Password*"
                name="newPassword"
                placeholder="Enter new password"
                suffixIcon="closeEye"
              />
              <TextField
                type="password"
                label="Confirm New Password*"
                name="confirmPassword"
                placeholder="Confirm new password"
                suffixIcon="closeEye"
              />
              <div className="action-button mt-10">
                <Button type="submit" variant="submit-button" >
                  OK
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {isLoading && <Loader />}
    </div>
  )
}
